import * as Sentry from "@sentry/react";

import { logGenericEvent } from "./analytics_client";
import { ResponseError } from "./api_errors";

export type SentryContext = {
  user?: {
    id: string;
  };
  tags?: Record<string, string>;
};

export type AmplitudeEvent = {
  eventName: string;
  eventProperties: Record<string, string>;
};

export type ReportErrorOptions = {
  sendToSentry?: boolean;
  sendToAmplitude?: boolean;
  sentryContext?: SentryContext;
  amplitudeEvent?: AmplitudeEvent;
};

export const setSentryContext = (
  scope: Sentry.Scope,
  context: SentryContext
) => {
  if (context.user) {
    scope.setUser(context.user);
  } else if (window.virta?.user?.virta_id) {
    scope.setUser({ id: window.virta.user.virta_id });
  }

  for (const tag in context.tags) {
    scope.setTag(tag, context.tags[tag]);
  }
};

export const reportGenericError = (
  e: unknown,
  options?: Omit<ReportErrorOptions, "sendToAmplitude">
) => {
  const { sendToSentry = true, sentryContext, amplitudeEvent } = options ?? {};

  if (sendToSentry) {
    Sentry.withScope((scope) => {
      if (sentryContext) {
        setSentryContext(scope, sentryContext);
      }

      Sentry.captureException(e);
    });
  }

  if (amplitudeEvent) {
    logGenericEvent(amplitudeEvent.eventName, amplitudeEvent.eventProperties);
  }
};

export const reportAPIError = (
  e: ResponseError,
  options?: Omit<ReportErrorOptions, "sentryContext" | "amplitudeEvent">
) => {
  const { sendToAmplitude = true, sendToSentry = true } = options ?? {};
  const reportOptions: ReportErrorOptions = {};

  if (sendToSentry) {
    reportOptions.sentryContext = {
      tags: {
        endpoint: e.url,
        status: String(e.status),
      },
    };
  }

  if (sendToAmplitude) {
    reportOptions.amplitudeEvent = {
      eventName: "Encountered API Error",
      eventProperties: {
        endpoint: e.url,
        status: String(e.status),
      },
    };
  }

  return reportGenericError(e, reportOptions);
};

export const reportAnyError = (e: unknown, options?: ReportErrorOptions) => {
  if (e instanceof ResponseError) {
    reportAPIError(e, options);
  } else {
    reportGenericError(e, options);
  }
};
