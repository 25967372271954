import React from "react";
import { View } from "react-native";

import { Portal } from "@virtahealth/design-components";
import { Icon } from "@virtahealth/design-icons";
import { FlexItem, Row } from "@virtahealth/design-layout";
import { colors } from "@virtahealth/design-styles";

import { useNavBarBreakpoints } from "../utils";

interface BaseNaveBarProps {
  children?: React.ReactNode;
  secondaryContent?: React.ReactNode;
}

// Base Navigation bar component that only handles layout, logo size and placement
export const BaseNavBar = ({
  children,
  secondaryContent,
}: BaseNaveBarProps) => {
  const { isSmallScreen, isLargeScreen } = useNavBarBreakpoints();

  const OPTICAL_LOGO_SIZE = isSmallScreen ? 24 : 48;

  return (
    <Row
      justify="space-between"
      align="center"
      borderBottomColor="neutral-300"
      borderBottomWidth="thin"
      width="100%"
      padding={isSmallScreen ? 1.5 : 2.5}
      gap={1}
    >
      <FlexItem>
        <Row align="center">
          <Icon name="logo-horizontal" height={OPTICAL_LOGO_SIZE} />
        </Row>
      </FlexItem>
      {!isLargeScreen ? (
        <Portal>
          <View
            // @ts-ignore fixed exists in web but not react-native-web
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              margin: 0,
              borderTopWidth: 1,
              borderTopColor: colors["neutral-300"],
            }}
          >
            {children}
          </View>
        </Portal>
      ) : (
        <FlexItem grow={1}>
          <Row justify="center">{children}</Row>
        </FlexItem>
      )}
      {secondaryContent && <FlexItem>{secondaryContent}</FlexItem>}
    </Row>
  );
};
