import { ApolloClient } from "@apollo/client";
import React from "react";

import gqlClient from "../gqlClient";

export function useGraphQLClient(useCache = false) {
  const [graphQLClient, setGraphQLClient] = React.useState<ApolloClient<any>>();

  React.useEffect(() => {
    const setupClient = async () => {
      const client = await gqlClient(useCache);

      if (client) {
        setGraphQLClient(client);
      }
    };

    setupClient();
  }, [useCache]);

  return graphQLClient;
}
