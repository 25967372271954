import { set } from "lodash";
import React from "react";

import { Spinner } from "@virtahealth/design-components";
import { PaymentInfoPage } from "@virtahealth/experiences";
import {
  ZuoraPaymentInfo,
  ZuoraPaymentInfoRequest,
} from "@virtahealth/experiences/src/Payment/types";

import { useRequestContext } from "../contexts/request";

import styles from "./styles.css";

export const PaymentPage = () => {
  const [paymentInfo, setPaymentInfo] = React.useState();
  const { epGet, epPost } = useRequestContext();

  React.useEffect(() => {
    const getPaymentInfo = async () => {
      const paymentInfoResponse = await epGet("/payment_info?use_pa_app=true");
      setPaymentInfo(paymentInfoResponse);
    };
    getPaymentInfo();
  }, []);

  const submitPaymentInfo = (request: ZuoraPaymentInfoRequest) =>
    epPost("/payment_info", request).then((newInfo: ZuoraPaymentInfo) => {
      const newPaymentInfo = paymentInfo
        ? set(paymentInfo, "creditCard", newInfo.creditCard)
        : undefined;
      setPaymentInfo(newPaymentInfo);
      return newInfo;
    });

  return paymentInfo ? (
    <div className={styles.paymentContainer}>
      <PaymentInfoPage
        addPaymentInfo={submitPaymentInfo as any}
        paymentInfo={paymentInfo}
        isPaymentLoaded={!!paymentInfo}
      />
    </div>
  ) : (
    <Spinner />
  );
};
