import { FormikValues } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Spinner } from "@virtahealth/design-components";
import { DynamicForm } from "@virtahealth/experiences";
import { DynamicFormSchema } from "@virtahealth/experiences/src/DynamicForm/types";

import { useRequestContext } from "../contexts/request";
import { useSchema } from "../hooks/schema";

import styles from "./styles.css";

const HealthInformationExchange: React.FC = () => {
  const [isRequesting, setIsRequesting] = React.useState(false);
  const schema = useSchema("/forms/health-information-exchange");
  const [step, setStep] = React.useState(0);

  const { epPatch } = useRequestContext();
  const history = useHistory();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const labsToken = params.get("token_id") || "";

  const redirectToLogin = () => {
    history.push("/user/login");
  };

  const updateExternalLabsOptOut = async (optOut: boolean) => {
    if (!optOut) {
      redirectToLogin();
    }

    setIsRequesting(true);
    await epPatch(`/lab_options/external_labs_opt_out/${labsToken}`, {});
    setIsRequesting(false);
  };

  const handleSubmit = async (values: FormikValues) => {
    if (step === 0) {
      await updateExternalLabsOptOut(values.hieOptOut);
      setStep(1);
    }
  };

  return isEmpty(schema) ? (
    <div className={styles.spinnerContainer}>
      <Spinner size="large" />
    </div>
  ) : (
    <div className={styles.hieContainer}>
      <DynamicForm
        onSubmit={handleSubmit}
        isApiLoading={isRequesting}
        schema={schema as unknown as DynamicFormSchema}
        page={step}
      />
    </div>
  );
};

export default HealthInformationExchange;
