import React from "react";
import { FieldValues } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { BodyText, Link } from "@virtahealth/design-components";
import { Margin, Row, Stack } from "@virtahealth/design-layout";
import { ProgressWText, useRequestContext } from "@virtahealth/experiences";
import { ManagedDynamicForm } from "@virtahealth/participant-app";
import { messages } from "@virtahealth/utils";

import {
  LinkedAccountIds,
  PersonAttributes,
  UsersEndpointData,
} from "../account_creation_orchestrator/types";
import { MAX_FORM_WIDTH } from "../constants/formSizes";
import { useAuth } from "../contexts/auth";
import { useScreenViewEffect } from "../hooks/amplitudeEffects";
import { getSchemaV2 } from "../hooks/schema";
import { useZendeskWidget } from "../hooks/zendeskEffects";
import { reportAnyError } from "../utils/errors";

interface FormValues {
  dateOfBirth: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  privacyPracticesAccepted: boolean;
  smsOptInAccepted: string;
}

interface Props {
  employerIdParam: string | undefined;
  personAttributes: PersonAttributes | FieldValues;
  getLinkedAccountIds: () => LinkedAccountIds | undefined;
}

export const AccountCreation: React.FC<Props> = ({
  employerIdParam,
  personAttributes,
  getLinkedAccountIds,
}: Props) => {
  useZendeskWidget();
  useScreenViewEffect("Account Creation");

  const auth = useAuth();
  const history = useHistory();

  const { epPost } = useRequestContext();

  const getSchema = getSchemaV2("account-creation", personAttributes);

  const onSubmit = async (values: FormValues) => {
    const {
      dateOfBirth,
      email,
      firstName,
      lastName,
      password,
      phone,
      privacyPracticesAccepted,
    } = values;
    try {
      const data: UsersEndpointData = {
        earlyQualificationTask: {
          isComplete: false,
          isPayingOutOfPocket: !employerIdParam,
          referrerUrl: document.referrer,
          employerId: employerIdParam,
        },
        user: {
          dateOfBirth,
          email,
          firstName,
          lastName,
          linkedAccountIds: getLinkedAccountIds(),
          password,
          primaryEmail: email,
          primaryPhone: phone,
          tosAccepted: privacyPracticesAccepted,
        },
        coverage: {
          memberId: personAttributes?.memberId,
          groupId: personAttributes?.groupId,
        },
        privacyPracticesAccepted,
        smsOptInAccepted: values?.smsOptInAccepted === "true",
      };

      await epPost("/users", data);
    } catch (e) {
      reportAnyError(e);
      throw e; // Handled by the DynamicForm - renders an error notification
    }
  };

  const handleCompletion = async () => {
    auth.logIn("applicant");
    history.push("/enrollment/sms-verification");
  };

  return (
    <Stack gap={2} width="100%" maxWidth={MAX_FORM_WIDTH} padding={2}>
      <Margin horizontal={2}>
        <ProgressWText percent={0} />
      </Margin>
      <ManagedDynamicForm
        schemaCall={getSchema}
        queryKey="account-creation"
        onSubmit={onSubmit}
        onCompleted={handleCompletion}
      />
      <Row justify="center" gap={1}>
        <BodyText size="medium" text={messages.alreadyHaveApplication} />
        <Link
          text={messages.logIn}
          onPress={() => history.push("/user/login")}
        />
      </Row>
    </Stack>
  );
};

export default AccountCreation;
