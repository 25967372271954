import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import { Spinner } from "@virtahealth/design-components";
import {
  ActionsProvider,
  ApplicantTaskProvider,
  DetailedPaymentTerms,
  FormSchemaProvider,
  HealthHistoryProvider,
  NavigationProvider as NavProvider,
  PaymentTermsOfUse,
} from "@virtahealth/experiences";

import AboutYou from "../about_you";
import BasicInformationPage from "../basic_information";
import { useEnrollmentContext } from "../contexts/enrollment";
import ConvertToPatient from "../convertToPatient";
import DownloadAppPage from "../download_app";
import Eligibility from "../eligibility";
import EnrollmentStatusPage from "../enrollment_status";
import FindALabPage from "../find_a_lab";
import HealthProfile from "../health_profile";
import { useCancelFetchesOnComponentDismount } from "../hooks";
import NextStepsPage from "../next_steps";
import { PaymentPage } from "../payment";
import ReferralComplete from "../referral_complete";
import {
  ScheduleHpCall,
  ScheduleMandatoryEcCall,
  ScheduleQcCall,
} from "../schedule_call";
import { SmsUpdate, SmsVerification } from "../sms_verification";

import { EnrollmentRouter } from "./EnrollmentRouter";
import { useAmplitudeUser, useFeatureFlags, useSentryUser } from "./hooks";
import styles from "./styles.css";
import { getEnv } from "./utils";

const ENV = getEnv();

export const Enrollment = () => {
  const abortController = new AbortController();
  const enrollmentContext = useEnrollmentContext();
  const { isContextLoaded, isRetrievingStatus, updateEnrollmentStatus } =
    enrollmentContext;
  const location = useLocation();

  const history = useHistory();
  const isReferralFlow = location.pathname.includes("referral");

  const [continueInWeb, setContinueInWeb] = React.useState<boolean>(false);
  const [skipCall, setSkipCall] = React.useState<boolean>(false);

  useCancelFetchesOnComponentDismount(abortController);

  React.useEffect(() => {
    /**
     * Enrollment status is fetched here on initial page loads - this includes refreshing the page and
     * navigating to a URL directly. This effect will not update enrollment status on navigations
     * between pages under /enrollment. updateEnrollmentStatus should be called on form submissons or
     * page continues to update the enrollment status if data is expected to change. See the
     * BI form for an example.
     */
    updateEnrollmentStatus({ abortController });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSentryUser();
  useAmplitudeUser({ isReferralFlow });
  useFeatureFlags();

  if (isRetrievingStatus || !isContextLoaded) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <ApplicantTaskProvider>
      <FormSchemaProvider>
        <ActionsProvider env={ENV}>
          {/* @ts-expect-error these types do not match */}
          <NavProvider navigateTo={history.push}>
            <HealthHistoryProvider enrollment={enrollmentContext}>
              <Switch>
                <Route path="/enrollment" exact>
                  <EnrollmentRouter
                    skipCall={skipCall}
                    continueInWeb={continueInWeb}
                  />
                </Route>
                <Route
                  path="/enrollment/sms-verification"
                  component={SmsVerification}
                />
                <Route path="/enrollment/sms-update" component={SmsUpdate} />
                <Route
                  path="/enrollment/basic-information"
                  component={BasicInformationPage}
                />
                <Route
                  path="/enrollment/enrollment-status"
                  component={EnrollmentStatusPage}
                />
                <Route
                  path="/enrollment/referral-enrollment-status"
                  component={EnrollmentStatusPage}
                />
                <Route path="/enrollment/about-you" component={AboutYou} />
                <Route path="/enrollment/consultation">
                  <ScheduleMandatoryEcCall setSkipCall={setSkipCall} />
                </Route>
                <Route
                  path="/enrollment/provider-visit"
                  component={ScheduleHpCall}
                />
                <Route
                  path="/enrollment/qualification-call"
                  component={ScheduleQcCall}
                />
                <Route
                  path="/enrollment/health-profile"
                  component={HealthProfile}
                />
                <Route
                  path="/enrollment/next-steps"
                  component={NextStepsPage}
                />
                <Route path="/enrollment/payment" component={PaymentPage} />
                <Route
                  path="/enrollment/referral-complete"
                  component={ReferralComplete}
                />
                <Route path="/enrollment/eligibility" component={Eligibility} />
                <Route
                  path="/enrollment/referral-eligibility"
                  component={Eligibility}
                />
                <Route path="/enrollment/find-a-lab" component={FindALabPage} />
                <Route
                  path="/enrollment/payment-terms-of-use"
                  component={PaymentTermsOfUse}
                />
                <Route
                  path="/enrollment/detailed-payment-terms"
                  component={DetailedPaymentTerms}
                />
                <Route path="/enrollment/download-app">
                  <DownloadAppPage setContinueInWeb={setContinueInWeb} />
                </Route>
                <Route
                  path="/enrollment/convert-to-patient"
                  component={ConvertToPatient}
                />
                <Redirect to="/enrollment" />
              </Switch>
            </HealthHistoryProvider>
          </NavProvider>
        </ActionsProvider>
      </FormSchemaProvider>
    </ApplicantTaskProvider>
  );
};

export default Enrollment;
