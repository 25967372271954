import React from "react";

import { AboutYou as AtlasAboutYou } from "@virtahealth/experiences";

import styles from "./styles.css";

const AboutYou = () => (
  <div className={styles.healthHistoryContainer}>
    <AtlasAboutYou />
  </div>
);

export default AboutYou;
