import { camelCase, map, uniq } from "lodash";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Box, Flex, Margin } from "@virtahealth/design-layout";
import {
  Disqualification,
  EnrollmentStatusScreenAppRevamp,
  EnrollmentStatusV2,
  NonQualification,
  ProgressWText,
} from "@virtahealth/experiences";

import { useEnrollmentContext } from "../contexts/enrollment";
import { useScreenViewEffect } from "../hooks/amplitudeEffects";

export const EnrollmentStatus = () => {
  useScreenViewEffect("Enrollment Status");

  const {
    enrollmentStatus,
    getIsDQed,
    getIsPrelaunch,
    getIsSoftWaitlisted,
    getIsWaitlisted,
    opsTasks,
    setHasJustSubmittedBI,
    segments,
    scheduledQcCalls,
  } = useEnrollmentContext();
  const history = useHistory();
  const location = useLocation();
  const isWaitlist = getIsWaitlisted();
  const isSoftWaitlisted = getIsSoftWaitlisted();
  const isNQ = segments.employerEligibility === "unqualified";
  const isDQ = getIsDQed();
  const isPrelaunch = getIsPrelaunch();

  let status: EnrollmentStatusV2 = "qualified";
  if (isNQ) {
    status = "not_qualified";
  } else if (isDQ) {
    status = "disqualified";
  } else if (isWaitlist || isSoftWaitlisted) {
    status = "waitlisted";
  } else if (isPrelaunch) {
    status = "prelaunch";
  }

  const isReferral = location.pathname.includes("referral");

  return (
    <Margin top={5} bottom={3}>
      {status === "qualified" && (
        <Flex direction="column" paddingBottom={3}>
          <ProgressWText percent={100} />
        </Flex>
      )}
      <Box backgroundColor="white" maxWidth={575} borderRadius="D" padding={4}>
        <EnrollmentStatusScreenAppRevamp
          scheduledQcCalls={scheduledQcCalls}
          deployment={opsTasks.deployment.applicationDisplayName}
          deploymentKey={opsTasks.deployment.deploymentKey}
          goLiveDate={opsTasks.deployment.goLiveDate}
          status={status}
          isReferral={isReferral}
          disqualifications={
            (isDQ &&
              enrollmentStatus.disqualifications.map(
                (dq) =>
                  camelCase(`dq_${dq.disqualificationId}`) as Disqualification
              )) ||
            []
          }
          nonQualifications={map(
            uniq(opsTasks.deployment.eligibilityQualifications),
            (nq) => camelCase(`nq_${nq}`) as NonQualification
          )}
          onEditApplication={() => {
            setHasJustSubmittedBI(false);
            if (isReferral) {
              history.goBack();
            } else {
              history.push("/enrollment/basic-information");
            }
          }}
          onScheduleTimeWithAdvisor={() => {
            setHasJustSubmittedBI(false);

            let onContinueLocation = "/enrollment";

            if (isReferral) {
              onContinueLocation = "/enrollment/referral-eligibility";
            } else if (isNQ && segments.qualificationCall === "required") {
              onContinueLocation = "/enrollment/qualification-call";
            }

            history.push(onContinueLocation);
          }}
          goToEnrollment={() => {
            history.push("/enrollment");
          }}
        />
      </Box>
    </Margin>
  );
};

export default EnrollmentStatus;
