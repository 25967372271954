import React from "react";

import { ErrorView } from "@virtahealth/experiences";

import { useScreenViewEffect } from "../../hooks/amplitudeEffects";

import styles from "./styles.css";

const ErrorPage = () => {
  useScreenViewEffect("App Error");

  return (
    <div className={styles.errorContainer}>
      <ErrorView />
    </div>
  );
};

export default ErrorPage;
