import React from "react";

export const useZendeskWidget = () => {
  const [zeLoaded, setZeLoaded] = React.useState(false);

  React.useEffect(() => {
    // Check if chat loaded every 500ms
    const ticker = setInterval(() => {
      if (window.zE) {
        setZeLoaded(true);
        clearInterval(ticker);
      }
    }, 500);

    return () => {
      clearInterval(ticker);
    };
  }, []);

  React.useEffect(() => {
    if (zeLoaded) {
      try {
        window.zE("webWidget", "updateSettings", {
          webWidget: {
            chat: {
              suppress: false,
            },
            contactForm: {
              suppress: false,
            },
            helpCenter: {
              suppress: false,
            },
          },
        });
        // Must be on the new messaging version of the widget
      } catch (e) {
        window.zE("messenger", "show");
      }
    }

    return () => {
      if (zeLoaded) {
        try {
          window.zE("webWidget", "updateSettings", {
            webWidget: {
              chat: {
                suppress: true,
              },
              contactForm: {
                suppress: true,
              },
              helpCenter: {
                suppress: true,
              },
            },
          });
          // Must be on the new messaging version of the widget
        } catch (e) {
          window.zE("messenger", "hide");
        }
      }
    };
  }, [zeLoaded]);
};
