import React from "react";
import { Redirect } from "react-router-dom";

import { isMobileBrowser } from "@virtahealth/utils";

import { useEnrollmentContext } from "../contexts/enrollment";

interface RouterProps {
  skipCall: boolean;
  continueInWeb: boolean;
}

export const EnrollmentRouter = ({ skipCall, continueInWeb }: RouterProps) => {
  const { getIsSpecialStatus, getIsDQed, segments, enrollmentSteps } =
    useEnrollmentContext();

  // If /enrollment is requested unspecifically(exactly), we should generate the route from state

  if (enrollmentSteps.smsVerification === "pending_required") {
    return <Redirect push to="/enrollment/sms-verification" />;
  }

  // DQed apps should always be presented the status page.
  // - BI is not submitted for DQed apps, so we need to route to enrollment status separately.
  if (getIsDQed()) {
    return <Redirect push to="/enrollment/enrollment-status" />;
  }

  // BI needs to be completed before continuing elsewhere.
  if (enrollmentSteps.basicInformation === "pending_required") {
    return <Redirect push to="/enrollment/basic-information" />;
  }

  // Prelaunch, Waitlist, and Unqualified and should always go here.
  if (getIsSpecialStatus() || segments.employerEligibility === "unqualified") {
    return <Redirect push to="/enrollment/enrollment-status" />;
  }

  if (enrollmentSteps.enrollmentCall === "pending_required" && !skipCall) {
    return <Redirect push to="/enrollment/consultation" />;
  }

  if (enrollmentSteps.qualificationCall === "pending_required") {
    return <Redirect push to="/enrollment/qualification-call" />;
  }

  switch (segments.webApplicantFlow) {
    case "type-1":
      return <Type1Router continueInWeb={continueInWeb} />;
    case "full-page-by-page":
    default:
      return <FullPageByPageRouter continueInWeb={continueInWeb} />;
  }
};

export const Type1Router = ({
  continueInWeb,
}: Pick<RouterProps, "continueInWeb">) => {
  const { enrollmentSteps } = useEnrollmentContext();

  if (enrollmentSteps.eligibility === "pending_required") {
    return <Redirect push to="/enrollment/eligibility" />;
  }

  if (enrollmentSteps.aboutYou === "pending_required") {
    return <Redirect push to="/enrollment/about-you" />;
  }

  if (!continueInWeb) {
    return <Redirect push to="/enrollment/download-app" />;
  }

  return <Redirect push to="/enrollment/next-steps" />;
};

export const FullPageByPageRouter = ({
  continueInWeb,
}: Pick<RouterProps, "continueInWeb">) => {
  const { enrollmentSteps } = useEnrollmentContext();

  if (enrollmentSteps.aboutYou === "pending_required") {
    return <Redirect push to="/enrollment/about-you" />;
  }

  if (enrollmentSteps.eligibility === "pending_required") {
    return <Redirect push to="/enrollment/eligibility" />;
  }

  if (enrollmentSteps.healthHistory === "pending_required") {
    return <Redirect push to="/enrollment/health-profile" />;
  }

  if (!continueInWeb && isMobileBrowser()) {
    return <Redirect push to="/enrollment/download-app" />;
  }

  return <Redirect push to="/enrollment/next-steps" />;
};
