import { gql } from "@virtahealth/utils";

export const ASSIGN_ACTION_MUTATION = gql`
  mutation AssignAction($virtaId: String!, $actionContentId: String!) {
    assignAction(virtaId: $virtaId, actionContentId: $actionContentId) {
      patientActionId
    }
  }
`;

export const ASSIGN_ACTIONS_MUTATION = gql`
  mutation AssignActions($virtaId: String!, $actionContentIds: [String]!) {
    assignActions(virtaId: $virtaId, actionContentIds: $actionContentIds) {
      actions {
        virtaId
        statusCode
        statusReason
        actionContentId
      }
    }
  }
`;

export const ASSIGN_SERIES_MUTATION = gql`
  mutation AssignSeries($virtaId: String!, $seriesContentIds: [String]!) {
    assignSeries(virtaId: $virtaId, seriesContentIds: $seriesContentIds) {
      series {
        virtaId
        statusCode
        statusReason
        seriesContentId
      }
    }
  }
`;
