import React from "react";
import { Platform } from "react-native";

import { Notification } from "@virtahealth/design-components";
import { DynamicForm, DynamicFormProps } from "@virtahealth/design-forms";
import { Flex } from "@virtahealth/design-layout";
import { getMessageFromTreeOrKey } from "@virtahealth/utils";

export const platformChecks = {
  isWeb: Platform.OS === "web",
  isAndroid: Platform.OS === "android",
  isIos: Platform.OS === "ios",
};

const { isWeb } = platformChecks;

interface SmsDynamicFormProps extends DynamicFormProps {
  error?: string;
}

export function SmsDynamicForm({ error, ...props }: SmsDynamicFormProps) {
  return (
    <DynamicForm.Root {...props}>
      <Flex direction="column" gap={5}>
        {isWeb && (
          <Flex justify="center">
            <DynamicForm.Heading textAlign="center" />
          </Flex>
        )}
        <Flex
          backgroundColor="white"
          borderRadius={isWeb ? "D" : undefined}
          direction="column"
          gap={1.5}
          padding={2.5}
        >
          <DynamicForm.Page />

          <Flex direction="column" gap={1}>
            <DynamicForm.ApiErrors />
            <DynamicForm.FormErrors />
            {error && (
              <Notification
                variant="error"
                description={getMessageFromTreeOrKey(error)}
              />
            )}
          </Flex>

          <DynamicForm.ProgressTracker />
          <DynamicForm.Actions />
        </Flex>
      </Flex>
    </DynamicForm.Root>
  );
}

export default SmsDynamicForm;
