import * as Sentry from "@sentry/browser";
import { get, isEmpty } from "lodash";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { VirtaContextComponents } from "@virtahealth/components";
import { Spinner } from "@virtahealth/design-components";
import { DynamicForm } from "@virtahealth/experiences";
import { DynamicFormSchema } from "@virtahealth/experiences/src/DynamicForm/types";

import { useAuth } from "../contexts/auth";
import { useRequestContext } from "../contexts/request";
import { sendAmplitudeScreenViewEvent } from "../hooks/amplitudeEffects";
import { useSchema } from "../hooks/schema";
import { post } from "../utils/api";

import styles from "./styles.css";

interface ActivateUserRequest {
  dateOfBirth: string;
  password?: string;
  virtaId?: string;
  consent?: boolean;
}

const pageNames = [
  "confirmDOB",
  "setPassword",
  "successfulPasswordSet",
  "identityConfirmationFailed",
  "accountLocked",
  "somethingWentWrong",
];

const getPageNum = (pageName: string): number => pageNames.indexOf(pageName);

export const AccountActivation = () => {
  const { analyticsClient } = React.useContext(VirtaContextComponents);
  const [pageNum, setPageNum] = React.useState(0);
  const [password, setPassword] = React.useState("");
  const [prevPageNum, setPrevPageNum] = React.useState(0);
  const [primaryEmail, setPrimaryEmail] = React.useState("");
  const [dateOfBirth, setDateOfBirth] = React.useState("");
  const abortController = new AbortController();
  const auth = useAuth();
  const { push } = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const virtaId = query.get("v_id");
  const { epPost } = useRequestContext();
  const schema = useSchema("/forms/account-activation");

  const handleGoBack = () => {
    const tmpPrevPageNum = prevPageNum;
    setPrevPageNum(pageNum);
    setPageNum(tmpPrevPageNum);
  };
  const handleSubmit = async (values: unknown) => {
    if (pageNum === getPageNum("confirmDOB")) {
      const dateOfBirth = get(values, "dateOfBirth");
      setDateOfBirth(dateOfBirth);
      setPageNum(getPageNum("setPassword"));
    } else if (pageNum === getPageNum("setPassword")) {
      const password = get(values, "password");
      const consent = get(values, "privacyPracticesAccepted");
      try {
        const response = await epPost("/auth/activate", {
          dateOfBirth,
          virtaId,
          password,
          consent,
        } as ActivateUserRequest);
        if (abortController.signal.aborted) {
          return;
        }
        setPrimaryEmail(response.primaryEmail);
        setPassword(password);
        setPageNum(getPageNum("successfulPasswordSet"));
      } catch (e) {
        Sentry.captureException(e);
        setPageNum(getPageNum("somethingWentWrong"));
      }
    }
    return;
  };

  React.useEffect(() => {
    if (
      pageNum === getPageNum("successfulPasswordSet") &&
      primaryEmail &&
      password
    ) {
      setTimeout(async () => {
        try {
          const { accountType } = await post("/user/login", {
            email: primaryEmail,
            pwd: password,
          });

          auth.logIn(accountType);
          push("/enrollment");
        } catch (e) {
          if (e.status === 401) {
            setPageNum(getPageNum("somethingWentWrong"));
          }
          Sentry.captureException(e);
        }
      }, 3000);
      if (abortController.signal.aborted) {
        return;
      }
    }

    return () => abortController.abort();
  }, [pageNum, primaryEmail, password]);

  return isEmpty(schema) ? (
    <Spinner />
  ) : (
    <div className={styles.accountActivationContainer}>
      <DynamicForm
        schema={schema as unknown as DynamicFormSchema}
        page={pageNum}
        isApiLoading={false}
        onGoBack={handleGoBack}
        onSubmit={handleSubmit}
        sendAmplitudeLogEvent={sendAmplitudeScreenViewEvent(analyticsClient)}
      />
    </div>
  );
};

export default AccountActivation;
