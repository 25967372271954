import { useWindowDimensions } from "react-native";

import {
  MOBILE_STANDARD_BREAKPOINT,
  WINDOW_WIDTH_MD,
  isMobileBrowser,
} from "@virtahealth/utils/src/windowUtils";

export const MODAL_MAX_WIDTH = 575;

export const useNavBarBreakpoints = () => {
  const { width: screenWidth } = useWindowDimensions();

  const isSmallScreen = screenWidth <= MOBILE_STANDARD_BREAKPOINT;
  const isLargeScreen = screenWidth > WINDOW_WIDTH_MD;
  const isMobile = isMobileBrowser();

  return {
    isSmallScreen,
    isLargeScreen,
    isMobile,
  };
};
