import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";

import { SchemaCallReturn } from "@virtahealth/participant-app";

import { useRequestContext } from "../contexts/request";
import { epGet } from "../utils/ep_api";

export const useSchema = (schemaEndpoint: string) => {
  const { epGet } = useRequestContext();
  const [schema, setSchema] = useState(undefined);
  const abortController = new AbortController();

  useEffect(() => {
    (async () => {
      setSchema(
        await epGet(schemaEndpoint, { signal: abortController.signal })
      );
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  return schema;
};

export type SchemaTypes = "account-creation" | "basic-info";

export const getSchemaV2 = (
  schemaType: SchemaTypes,
  initialValueOverrides?: FieldValues
) => {
  const END_POINT = `api/v2/forms/${schemaType}`;

  return new Promise<SchemaCallReturn>((resolve) =>
    epGet(END_POINT).then(({ schema, initialValues }) => {
      resolve({
        schema,
        initialValues: {
          ...initialValues,
          ...(initialValueOverrides ? initialValueOverrides : {}),
        },
      });
    })
  );
};
