import { useContext, useEffect, useState } from "react";

import { VirtaContextComponents } from "@virtahealth/components";

export { sendAmplitudeScreenViewEvent } from "@virtahealth/experiences/src/EnrollmentForms/hooks/amplitudeEffects";

export const useScreenViewEffect = (screenName: string) => {
  const { analyticsClient } = useContext(VirtaContextComponents);
  const [loggedAmplitudeScreenViewEvent, setLoggedAmplitudeScreenViewEvent] =
    useState(false);

  useEffect(() => {
    analyticsClient?.logViewEvent(screenName, {
      screen_name: screenName,
      screen_path: window.location.pathname,
      url: window.location.href,
    });

    setLoggedAmplitudeScreenViewEvent(true);
  }, []);

  return { loggedAmplitudeScreenViewEvent };
};
