import { ApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { get } from "lodash";

import { gql } from "@virtahealth/utils";

import {
  ASSIGN_ACTIONS_MUTATION,
  ASSIGN_ACTION_MUTATION,
  ASSIGN_SERIES_MUTATION,
} from "../queries/actions";

export const retrieveAndCompleteActions = async (
  graphQLClient: ApolloClient<any>,
  virtaId: string,
  queryParams: { actionSlug?: string; actionContentId?: string }
) => {
  if (!queryParams.actionContentId && !queryParams.actionSlug) {
    return;
  }
  const { actionSlug = "", actionContentId } = queryParams;
  const slugParam = actionSlug ? `slug: "${actionSlug}", ` : "";
  const actionContentIdParam = actionContentId
    ? `actionContentId: "${actionContentId}", `
    : "";
  const otherQueryParams = slugParam + actionContentIdParam;
  try {
    const retrievedActions = await graphQLClient.query({
      query: gql`
        query GetAllActions {
          actions(virtaId: "${virtaId}", ${otherQueryParams}filterHasSeries: true, isActive: true) {
            oneTimeActions {
              patientActionId
            }
          }
        }
      `,
    });
    const singleActionList =
      retrievedActions.data?.actions?.oneTimeActions ?? [];
    singleActionList.forEach(async (action: { patientActionId: string }) => {
      const { patientActionId } = action;
      await graphQLClient.mutate({
        mutation: gql`
        mutation {
          markAction(virtaId: "${virtaId}",
          entityType: "checkmark",
          entityValue: "true",
          patientActionId: "${patientActionId}",
          userTimezone: "UTC",
          completedOnUtc: "${new Date().toUTCString()}") {
            hasNewHabitualAction,
            patientActionId
          }
        }
        `,
      });
    });
  } catch (e) {
    // Only logging error since non-blocking step
    Sentry.captureException(e);
  }
};

/**
 * @param graphQLClient
 * @param virtaId
 * @param enrollmentActionsToAssign
 * @deprecated moving action assignment to the backend. Call updateEnrollmentStatus instead
 */
export const assignActions = async (
  graphQLClient: ApolloClient<any>,
  virtaId: string,
  enrollmentActionsToAssign: string[]
) => {
  enrollmentActionsToAssign.forEach(async (actionToAssign) => {
    const actionContentId = get(
      window.env.enrollmentActions,
      actionToAssign,
      ""
    );
    if (actionContentId) {
      await graphQLClient?.mutate({
        mutation: ASSIGN_ACTION_MUTATION,
        variables: {
          virtaId,
          actionContentId,
        },
      });
    }
  });
};

/**
 * To replace assignActions method
 * @param graphQLClient
 * @param virtaId
 * @param enrollmentActionsToAssign
 * @deprecated moving action assignment to the backend. Call updateEnrollmentStatus instead
 */
export const assignBulkActions = async (
  graphQLClient: ApolloClient<any>,
  virtaId: string,
  enrollmentActionsToAssign: string[]
) => {
  const contentIds: string[] = [];
  enrollmentActionsToAssign.forEach(async (actionToAssign) => {
    const actionContentId = get(
      window.env.enrollmentActions,
      actionToAssign,
      ""
    );
    contentIds.push(actionContentId);
  });

  await graphQLClient?.mutate({
    mutation: ASSIGN_ACTIONS_MUTATION,
    variables: {
      virtaId,
      actionContentIds: contentIds,
    },
  });
};

/**
 * @param graphQLClient
 * @param virtaId
 * @param seriesToAssign
 * @deprecated moving action assignment to the backend. Call updateEnrollmentStatus instead
 */
export const assignSeries = async (
  graphQLClient: ApolloClient<any>,
  virtaId: string,
  seriesToAssign: string[]
) => {
  const contentIds: string[] = [];
  seriesToAssign.forEach(async (seriesToAssign) => {
    const seriesContentId = get(
      window.env.enrollmentSeries,
      seriesToAssign,
      ""
    );

    if (seriesContentId) {
      contentIds.push(seriesContentId);
    }
  });

  await graphQLClient?.mutate({
    mutation: ASSIGN_SERIES_MUTATION,
    variables: {
      virtaId,
      seriesContentIds: contentIds,
    },
  });
};
