import * as Sentry from "@sentry/browser";
import React from "react";

import { VirtaContextComponents } from "@virtahealth/components";
import { show_calendly_error } from "@virtahealth/experiences/src/EnrollmentForms/utils/ld_flag_constants";

import { DTP_DEPLOYMENT_KEY } from "../constants/Enrollment";
import { useEnrollmentContext } from "../contexts/enrollment";
import {
  identifyUser,
  identifyUserProperties,
} from "../utils/analytics_client";

export const useFeatureFlags = () => {
  const { launchDarkly } = React.useContext(VirtaContextComponents);
  const enrollmentContext = useEnrollmentContext();
  const {
    earlyQualification,
    segments,
    featureFlags,
    setFeatureFlags, // TODO: refactor in progress in an atlas PR by jj (ag-2828)
    user,
    enrollmentSteps,
  } = enrollmentContext;

  React.useEffect(() => {
    const { employerId, diabetesDiagnosis, createdOn } = earlyQualification;

    const loadPreBiFlags = async () => {
      await launchDarkly?.identify(user.virtaId, {
        primary_email: user.primaryEmail,
      });
    };

    const loadPostBiFlags = async () => {
      await launchDarkly?.identify(user.virtaId!, {
        application_start_date: createdOn,
        deployment: employerId || DTP_DEPLOYMENT_KEY,
        diagnosis: diabetesDiagnosis,
        employer_id: employerId || DTP_DEPLOYMENT_KEY,
        is_qualified_for_deployment:
          segments.employerEligibility === "qualified",
        preferred_language: user.preferredLanguage,
        last_name: user.lastName,
        email: user.primaryEmail,
        primary_email: user.primaryEmail,
      });

      setFeatureFlags?.({
        ...featureFlags,
        showCalendlyError: await launchDarkly?.getBooleanFlag(
          show_calendly_error,
          false
        ),
      });
    };

    if (launchDarkly && user.virtaId) {
      if (enrollmentSteps.basicInformation === "completed") {
        loadPostBiFlags();
      } else {
        loadPreBiFlags();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    launchDarkly,
    user,
    earlyQualification,
    // featureFlags, // these can't be added for infinite loop
    setFeatureFlags,
    segments.employerEligibility,
    enrollmentSteps,
  ]);
};

export const useSentryUser = () => {
  const {
    isContextLoaded: isContextInitialized,
    user: { virtaId },
  } = useEnrollmentContext();

  React.useEffect(() => {
    if (isContextInitialized && virtaId) {
      Sentry.configureScope((scope: Sentry.Scope) =>
        scope.setUser({ id: virtaId })
      );
    }
  }, [isContextInitialized, virtaId]);
};

interface UseAmplitudeUserProps {
  isReferralFlow?: boolean;
}

export const useAmplitudeUser = ({
  isReferralFlow = false,
}: UseAmplitudeUserProps) => {
  const { user, earlyQualification, isContextLoaded } = useEnrollmentContext();

  React.useEffect(() => {
    const { employerId, diabetesDiagnosis } = earlyQualification;
    const { virtaId, gender, lastName, preferredLanguage } = user;

    if (isContextLoaded && !isReferralFlow && virtaId) {
      identifyUser(virtaId);

      identifyUserProperties({
        is_test_user: lastName === "TESTRECORD",
        gender: gender,
        preferred_language: preferredLanguage,
        deployment: employerId,
        diagnosis: diabetesDiagnosis,
      });
    }
  }, [isContextLoaded, user, isReferralFlow, earlyQualification]);
};
