import { useEffect } from "react";

// TODO: swap references to use useEffectAsync instead
export const useCancelFetchesOnComponentDismount = (
  abortController: AbortController
) => {
  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);
};

/** Hook that should be used to make async requets in useEffect hooks.
 *
 * This hook standardizes the pattern of creating an AbortController, passing its signal to an async function,
 * and aborting the request when the component unmounts.
 *
 * Additionally, setState used in an async hook should only be called when the component is mounted which
 * can be achieved by passing the signal to the async function and checking if the signal is aborted before
 * calling setState.
 */
export type useEffectAsyncCallback<T> = (signal: AbortSignal) => Promise<T>;

export const useEffectAsync = <T>(
  effect: useEffectAsyncCallback<T>,
  dependencies?: readonly any[] // eslint-disable-line @typescript-eslint/no-explicit-any
): void => {
  useEffect(() => {
    const abortController = new AbortController();

    effect(abortController.signal);

    return () => abortController.abort();
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};
