import { find, get } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

import { VirtaContextComponents } from "@virtahealth/components";
import { FindALab } from "@virtahealth/experiences";
import { getLabRequisition as getLabRequisitionObject } from "@virtahealth/utils";

import PAModal from "../common-components/PAModal";
import { useEnrollmentContext } from "../contexts/enrollment";
import { useRequestContext } from "../contexts/request";
import { useScreenViewEffect } from "../hooks/amplitudeEffects";
import { getCommonPropertiesFromWindow } from "../lib/analytics";

export const FindALabPage = () => {
  const { user } = useEnrollmentContext();
  const [labOrderFormUrl, setLabOrderFormUrl] = React.useState("");
  const [labOrderFormPdf, setLabOrderFormPdf] = React.useState<Blob>();
  const { epGet } = useRequestContext();
  const { push } = useHistory();
  const { analyticsClient } = React.useContext(VirtaContextComponents);

  const getLabRequisition = async () => {
    const labReqObject = await getLabRequisitionObject(epGet);
    if (labReqObject?.pdf) {
      setLabOrderFormPdf(labReqObject.pdf);
    } else if (labReqObject?.url) {
      setLabOrderFormUrl(labReqObject.url);
    }
  };

  React.useEffect(() => {
    getLabRequisition();
  }, []);

  useScreenViewEffect("Find A Labcorp");

  return (
    <FindALab
      labOrderFormPdf={labOrderFormPdf}
      labOrderFormUrl={labOrderFormUrl}
      externalRedirectLocation={`https://www.labcorp.com/labs-and-appointments/results?address_single=${get(
        find(user.addresses, ["type", "home"]),
        "postalCode"
      )}&service=ROUTINE_PHLEBOTOMY&radius=25&gps=false`}
      // @ts-ignore modal type is not well defined
      PAModal={PAModal}
      onFindLabClick={() =>
        analyticsClient?.logClickEvent(
          "Find Labcorp",
          getCommonPropertiesFromWindow()
        )
      }
      onNoLabClick={() =>
        analyticsClient?.logClickEvent(
          "Open No Labcorp Modal",
          getCommonPropertiesFromWindow()
        )
      }
      onBack={() => {
        if (analyticsClient) {
          analyticsClient.logClickEvent(
            "Find Labcorp Back Button",
            getCommonPropertiesFromWindow()
          );
        }
        push("/enrollment");
      }}
      applicantFirstName={user.firstName ?? ""}
      applicantLastName={user.lastName ?? ""}
    />
  );
};

export default FindALabPage;
