import { FormikValues } from "formik";
import { isNil } from "lodash";

export interface Coverage {
  memberId: string;
  insuranceCarrier: string;
  employerId?: string;
  organizationIds?: string[];
  coverageId?: string;
  groupId?: string;
}

const createOrUpdateCoverageReq = (
  values: FormikValues,
  coverageId?: string
) => {
  const coverageData: Coverage = {
    memberId: values.memberId,
    insuranceCarrier: values.insuranceCarrier,
  };
  const isUpdate = !isNil(coverageId);
  if (isUpdate && coverageId) {
    coverageData.coverageId = coverageId;
  }

  if (values.employerId) {
    coverageData.employerId = values.employerId;
  }
  if (values.organizationId) {
    coverageData.organizationIds = [values.organizationId];
  }
  if (values.groupId) {
    coverageData.groupId = values.groupId;
  }

  return coverageData;
};

const updateUserReq = (values: FormikValues) => {
  const userData = {
    firstName: values.firstName,
    lastName: values.lastName,
    dateOfBirth: values.dateOfBirth,
  };
  return userData;
};

const uploadImageReq = (values: FormikValues) => {
  const formData = new FormData();
  formData.append("front", values.uploadImageFront);
  formData.append("back", values.uploadImageBack);
  formData.append("coverage_id", values.coverage_id);
  return formData;
};

export { createOrUpdateCoverageReq, uploadImageReq, updateUserReq };
