import { unstable_batchedUpdates } from "react-dom";

/** Wraps setState calls in a batched update to prevent unnecessary re-renders and potential race conditions.
 *
 * This should be used in async functions that call setState multiple times. setState calls in async functions
 * are not batched by default and will cause a re-render for each setState call - this may be unexpected
 * behavior. This is automatic in React 18 but we have yet to upgrade to it.
 *
 * The necessity of this function might indicate a larger problem with the component's design - perhaps
 * there may be better ways to design a component's state to encapsulate loading states, etc.
 *
 * See https://dev.to/devmoustafa97/do-you-know-unstablebatchedupdates-in-react-enforce-batching-state-update-5cn2
 **/
export const batchSetState = unstable_batchedUpdates;
