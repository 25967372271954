import * as React from "react";
import {
  EventScheduledEvent,
  InlineWidget,
  useCalendlyEventListener,
} from "react-calendly";
import { useWindowDimensions } from "react-native";

import { VirtaContextComponents } from "@virtahealth/components";
import { CalendlyEventType } from "@virtahealth/experiences";
import { sendAmplitudeLogEvent } from "@virtahealth/experiences/src/EnrollmentForms/hooks/amplitudeEffects";
import { MOBILE_STANDARD_BREAKPOINT } from "@virtahealth/utils/src/windowUtils";

import { SchedulerUI } from "./SchedulerUI";

export interface PatientCallSchedulerProps
  extends Pick<
    React.ComponentPropsWithoutRef<typeof InlineWidget>,
    "url" | "prefill"
  > {
  onSetAppointment: (appointment?: EventScheduledEvent) => void;
  onContinue: () => void;
  onSkip: () => void;
  isCallScheduled?: boolean;
  showCalendlyError?: boolean;
  widgetStyles?: Pick<
    React.ComponentPropsWithoutRef<typeof InlineWidget>,
    "styles"
  >;
  callType?: CalendlyEventType;
  isSchedulingOptional?: boolean;
  confirmationComponent?: React.ReactNode;
}

/**
 * This component wraps the Virta UI/Calendly widget
 *
 * It manages state, calls to services, and the DOM
 *
 * The Calendly widget relies on the DOM to insert an external
 * script, so it will only work in the browser
 *
 * The core functionality doesn't have a Storybook because Storybook's
 * Content Security Policy blocks external scripts from running
 */
export const PatientCallScheduler: React.FC<PatientCallSchedulerProps> = ({
  onSetAppointment,
  onContinue,
  onSkip,
  prefill,
  url,
  widgetStyles,
  confirmationComponent,
  isCallScheduled = false,
  callType,
  isSchedulingOptional = false,
  showCalendlyError = false,
}) => {
  const [appointment, setAppointment] =
    React.useState<EventScheduledEvent | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [internalShowCalendlyError, setInternalShowCalendlyError] =
    React.useState(showCalendlyError);
  const hasWidgetLoaded = React.useRef(false);
  const { analyticsClient } = React.useContext(VirtaContextComponents);

  const root = document.getElementById("root");

  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);

  const { width } = useWindowDimensions();
  const isSmallScreen = width <= MOBILE_STANDARD_BREAKPOINT;

  useCalendlyEventListener({
    onEventScheduled: (event) => {
      setAppointment(event);
    },
    onEventTypeViewed: () => {
      hasWidgetLoaded.current = true;
    },
  });

  React.useEffect(() => {
    if (appointment) {
      onSetAppointment(appointment);
    }
  }, [appointment, onSetAppointment]);

  React.useEffect(() => {
    const failureToLoadTimer = setTimeout(() => {
      if (!hasWidgetLoaded.current && !isSmallScreen) {
        const eventLogger = sendAmplitudeLogEvent(analyticsClient);
        eventLogger("Calendly Widget Failed to Load");
        setInternalShowCalendlyError(true);
      }
    }, 10000);
    return () => clearTimeout(failureToLoadTimer);
  }, [analyticsClient, isSmallScreen]);

  return (
    <SchedulerUI
      callType={callType}
      isCallScheduled={isCallScheduled}
      confirmationComponent={confirmationComponent}
      onContinue={onContinue}
      showCalendlyError={internalShowCalendlyError}
      onSkip={onSkip}
      root={root}
      url={url}
      prefill={prefill}
      closeModal={closeModal}
      isModalOpen={isModalOpen}
      appointment={appointment}
      isSmallScreen={isSmallScreen}
      isSchedulingOptional={isSchedulingOptional}
      openModal={openModal}
      widgetStyles={widgetStyles}
      sendAmplitudeLogEvent={sendAmplitudeLogEvent(analyticsClient)}
    />
  );
};
