import { FieldValues } from "react-hook-form";

import {
  LinkedAccountIds,
  PersonAttributes,
} from "../../../account_creation_orchestrator/types";

export const _buildCreateAccountPayload = (
  formData: FieldValues,
  employerId: string | null,
  personAttributes: PersonAttributes | FieldValues,
  locale: string,
  linkedAccountIds: LinkedAccountIds
) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const payload: any = {
    user: {
      first_name: formData["firstName"],
      last_name: formData["lastName"],
      email: formData["email"],
      primary_email: formData["email"],
      date_of_birth: formData["dateOfBirth"],
      primary_phone: formData["phone"],
      password: formData["password"],
      tos_accepted: formData["privacyPracticesAccepted"],
      linked_account_ids: linkedAccountIds,
    },
    early_qualification_task: {
      referrer_url: document.referrer,
      is_complete: false,
      employer_id: employerId,
      is_paying_out_of_pocket: !employerId,
    },
    coverage: {
      member_id: personAttributes?.memberId,
      group_id: personAttributes?.groupId,
    },
    language: locale,
    privacy_practices_accepted: formData["privacyPracticesAccepted"],
    sms_opt_in_accepted: formData["smsOptInAccepted"] || false,
  };

  return payload;
};

export const _buildCompleteBasicInformationPayload = (
  formData: FieldValues,
  employerId: string | null,
  locale: string
) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const payload: any = {
    employer_id: employerId,
    diabetes_diagnosis: formData["diabetesDiagnosis"],
    gender: formData["gender"],
    height_feet: parseInt(formData["heightFeet"]),
    height_inches: parseInt(formData["heightInches"]),
    weight_in_pounds: parseInt(formData["weightInPounds"]),
    is_complete: true,
    is_paying_out_of_pocket: !employerId,
    user_first_name: formData["firstName"],
    user_last_name: formData["lastName"],
    exclusions: formData["exclusions"],
    locale: locale,
  };

  if (formData["gender"] === "o" && formData["sexAtBirth"]) {
    payload["sex_at_birth"] = formData["sexAtBirth"];
  }

  if (formData["referralChannelOpt"]) {
    payload["referral_channel_opt"] = formData["referralChannelOpt"];
    if (formData["referralChannelOptOther"]) {
      payload["referral_channel_opt_other"] =
        formData["referralChannelOptOther"];
    }
  }

  return payload;
};
