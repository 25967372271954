import { isEmpty, omitBy, transform } from "lodash";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { VirtaContextComponents } from "@virtahealth/components";
import { Spinner } from "@virtahealth/design-components";
import { DynamicForm } from "@virtahealth/experiences";
import { DynamicFormSchema } from "@virtahealth/experiences/src/DynamicForm/types";

import { ApplyContext } from "../ApplyContext";
import { useAuth } from "../contexts/auth";
import { useEnrollmentContext } from "../contexts/enrollment";
import { useRequestContext } from "../contexts/request";
import { sendAmplitudeScreenViewEvent } from "../hooks/amplitudeEffects";
import { useSchema } from "../hooks/schema";
import { ResponseError } from "../utils/api_errors";
import { reportAnyError } from "../utils/errors";

import styles from "./styles.css";
import { convertStringBooleans } from "./utils";

const getErrorMessageKeyFromStatusCode = (statusCode: number) => {
  // Maps to a translation message key in Atlas
  if (statusCode === 400) {
    return "error400StatusCodeSubmit";
  }
  return "internalServerError";
};

const getErrorMessageKeyFromStatusCodeForBI = (statusCode: number) => {
  // Maps to a translation message key in Atlas
  if (statusCode === 422) {
    return "error422StatusCodeBSCA";
  } else {
    return getErrorMessageKeyFromStatusCode(statusCode);
  }
};

const ReferralInformation = () => {
  const [serverErrorMessageKey, setServerErrorMessageKey] = React.useState("");
  const abortController = new AbortController();
  const enrollment = useEnrollmentContext();
  const { analyticsClient } = React.useContext(VirtaContextComponents);
  const history = useHistory();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const applyContext = React.useContext(ApplyContext);
  const employerIdParam = params.get("employer_id") || undefined;
  const referrerParam = params.get("referrer") || undefined;
  const { epPost } = useRequestContext();
  const auth = useAuth();
  const schema = useSchema("/forms/referral-info");

  const shouldRedirect =
    enrollment?.enrollmentSteps?.basicInformation === "completed";

  React.useEffect(() => {
    const redirectUser = async () => {
      if (shouldRedirect) {
        history.push("/enrollment/referral-eligibility");
      }
    };
    redirectUser();
  }, [shouldRedirect]);

  React.useEffect(() => {
    if (!enrollment.isRetrievingStatus && enrollment.hasJustSubmittedBI) {
      const shouldRedirectToEnrollmentStatus =
        enrollment.getIsSpecialStatus() ||
        enrollment.segments.employerEligibility === "unqualified";
      if (shouldRedirectToEnrollmentStatus) {
        enrollment.setHasJustSubmittedBI(false);
        history.push("/enrollment/referral-enrollment-status");
      }
    }
  }, [enrollment.isRetrievingStatus]);

  return isEmpty(schema) ? (
    <div className={styles.spinnerContainer}>
      <Spinner size="large" />
    </div>
  ) : (
    <div className={styles.basicInformationContainer}>
      <DynamicForm
        initialValues={{
          earlyQualificationTask: applyContext.earlyQualificationTask,
          heightFeet: applyContext.earlyQualificationTask.heightFeet,
          heightInches: applyContext.earlyQualificationTask.heightInches,
          user: applyContext.user,
        }}
        externalErrorMessageKey={serverErrorMessageKey}
        onSubmit={async (values) => {
          enrollment.setHasJustSubmittedBI(true);

          const userValues = transform(
            omitBy(values.user, (val) => val === ""),
            convertStringBooleans,
            {}
          );
          /** The backend expects the payload to contain date_of_birth however the referral form stores
           * the date of birth as userDateOfBirth (note that the API network interface automatically
           * transforms casing for payloads). This is a temporary fix until the referral experience is
           * revamped.
           *
           * One other observation is that DOB was not being populated in userValues in any form
           * when using auto-fill - the referral form currently uses the old DynamicForm and this is
           * probably a specific issue to the old DynamicForm.
           */
          userValues.dateOfBirth = userValues.userDateOfBirth;

          const eqValues = transform(
            omitBy(values.earlyQualificationTask, (val) => val === ""),
            convertStringBooleans,
            {}
          );

          const eqTaskToSave = {
            ...values.earlyQualificationTask,
            employerId: employerIdParam,
            referralChannelOpt: "other",
            referralChannelOptOther: referrerParam,
            heightFeet: values.heightFeet,
            heightInches: values.heightInches,
          };

          if (applyContext.setEarlyQualificationTask) {
            applyContext.setEarlyQualificationTask(eqTaskToSave);
          }
          if (applyContext.setUser) {
            applyContext.setUser(userValues);
          }

          const eqTaskToSubmit = {
            ...eqValues,
            employerId: employerIdParam,
            referralChannelOpt: "other",
            referralChannelOptOther: "ext_" + referrerParam,
            isComplete: true,
            heightInInches:
              parseInt(values.heightFeet) * 12 + parseInt(values.heightInches),
            weightInPounds: parseInt(eqValues.weightInPounds),
          };

          try {
            const referralResponse = await epPost("/referral_users", {
              user: userValues,
              earlyQualificationTask: eqTaskToSubmit,
            });

            if (applyContext.setReferralResponse) {
              applyContext.setReferralResponse(referralResponse);
            }

            if (referralResponse.status.disqualifications.length > 0) {
              history.push("/apply/referral-disqualified");
              return;
            }

            auth.logIn("applicant");
          } catch (e: unknown) {
            let status = 500;

            if (e instanceof ResponseError) {
              status = e.status;
            }

            reportAnyError(e);
            setServerErrorMessageKey(
              getErrorMessageKeyFromStatusCodeForBI(status)
            );
          }

          await enrollment.updateEnrollmentStatus({ abortController });

          if (abortController.signal.aborted) {
            return;
          }
        }}
        schema={schema as unknown as DynamicFormSchema}
        page={0}
        isApiLoading={false}
        sendAmplitudeLogEvent={sendAmplitudeScreenViewEvent(analyticsClient)}
      />
    </div>
  );
};

export default ReferralInformation;
