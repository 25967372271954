import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Spinner } from "@virtahealth/design-components";
import { EmailVerified } from "@virtahealth/experiences";

import ErrorPage from "../common-components/ErrorPage";
import { useRequestContext } from "../contexts/request";
import { useScreenViewEffect } from "../hooks/amplitudeEffects";
import { reportAnyError } from "../utils/errors";

import styles from "./styles.css";

const EmailVerification: React.FC = () => {
  const history = useHistory();

  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const oobCode = params.get("oobCode") || undefined;
  const v_id = params.get("v_id") || params.get("virtaId") || undefined;
  const { epPost } = useRequestContext();

  const [isConfirmingEmail, setIsConfirmingEmail] = React.useState(true);
  const [error, setError] = React.useState<unknown>(null);

  const verifyOobCode = async () => {
    try {
      const url = `/account/confirm_email_verification?v_id=${v_id}&oob_code=${oobCode}`;
      await epPost(url, {});
      setIsConfirmingEmail(false);
    } catch (e: unknown) {
      reportAnyError(e);
      setError(e);
    }
  };

  const onContinue = () => {
    history.push("/user/login");
  };

  React.useEffect(() => {
    verifyOobCode();
  }, []);

  useScreenViewEffect("Email Verification");

  if (error) {
    return <ErrorPage />;
  }

  return isConfirmingEmail ? (
    <div className={styles.spinnerContainer}>
      <Spinner size="large" />
    </div>
  ) : (
    <div className={styles.emailVerificationContainer}>
      <EmailVerified onContinue={onContinue} />
    </div>
  );
};

export default EmailVerification;
