import React from "react";

import {
  Button,
  HeadingText,
  IconButton,
} from "@virtahealth/design-components";
import { Flex } from "@virtahealth/design-layout";
import { useBool } from "@virtahealth/hooks";
import { useVirtaIntl } from "@virtahealth/translations";
import { messages } from "@virtahealth/utils";

import { useAuth } from "../../contexts/auth";
import PAModal, { PAModalBody, PAModalHeader } from "../PAModal";

import { BaseNavBar } from "./components/BaseNavBar";
import { MODAL_MAX_WIDTH, useNavBarBreakpoints } from "./utils";

interface AuthenticatedNavBarProps {
  children?: React.ReactNode;
}

export const AuthenticatedNavBar = ({ children }: AuthenticatedNavBarProps) => {
  const auth = useAuth();
  const { isSmallScreen } = useNavBarBreakpoints();
  const [modalOpen, { on: openModal, off: closeModal }] = useBool(false);

  const onLogOut = async () => {
    closeModal();
    await auth.logOut();
  };

  return (
    <>
      <BaseNavBar
        secondaryContent={
          isSmallScreen ? (
            <IconButton name="logout" onPress={openModal} variant="text" />
          ) : (
            <Button
              variant="text"
              text={messages.logOut}
              before="logout"
              onPress={openModal}
              testID="log-out-button"
            />
          )
        }
      >
        {children}
      </BaseNavBar>
      <LogoutModal open={modalOpen} onClose={closeModal} onLogOut={onLogOut} />
    </>
  );
};

interface LogoutModalProps {
  open: boolean;
  onLogOut: () => void;
  onClose: () => void;
}

const LogoutModal = ({ open, onLogOut, onClose }: LogoutModalProps) => {
  const { formatMessageAccessibility } = useVirtaIntl();
  const { isSmallScreen } = useNavBarBreakpoints();

  return (
    <PAModal
      isOpen={open}
      style={{ content: { maxWidth: MODAL_MAX_WIDTH } }}
      requestCloseFn={onClose}
      contentLabel={formatMessageAccessibility(messages.logout)}
    >
      <PAModalHeader style={{ justifyContent: "center" }}>
        <HeadingText weight="bold" text={messages.confirmLogout} />
      </PAModalHeader>
      <PAModalBody>
        <Flex
          direction={isSmallScreen ? "column" : "row-reverse"}
          justify="center"
          gap={3}
        >
          <Button
            variant="outline"
            text={messages.logOut}
            onPress={onLogOut}
            testID="confirm-log-out-button"
          />
          <Button
            variant="solid"
            text={messages.staySignedIn}
            onPress={onClose}
          />
        </Flex>
      </PAModalBody>
    </PAModal>
  );
};
