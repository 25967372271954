import * as React from "react";
import { EventScheduledEvent, InlineWidget, PopupModal } from "react-calendly";

import {
  BodyText,
  Button,
  DecorativeText,
  HeadingText,
} from "@virtahealth/design-components";
import { Flex } from "@virtahealth/design-layout";
import { colors } from "@virtahealth/design-styles";
import {
  CalendlyErrorNotification,
  EnrollmentConsultationLiveNotification,
} from "@virtahealth/experiences";
import { messageTree, messages } from "@virtahealth/utils";

import { PatientCallSchedulerProps } from "./PatientCallScheduler";

interface SchedulerUIProps
  extends Omit<PatientCallSchedulerProps, "onSetAppointment"> {
  root: HTMLElement | null;
  closeModal: () => void;
  isModalOpen: boolean;
  appointment: EventScheduledEvent | null;
  isSmallScreen: boolean;
  isSchedulingOptional: boolean;
  openModal: () => void;
  sendAmplitudeLogEvent?: (eventTitle: string) => void;
}

/**
 * This component wraps the Calendly widget
 * with different styles & copy based on call type
 *
 * The presentation is broken out as its own component
 * to facilitate testing of display logic
 */
export const SchedulerUI = ({
  callType,
  isCallScheduled,
  confirmationComponent,
  onContinue,
  showCalendlyError,
  onSkip,
  root,
  url,
  prefill,
  closeModal,
  isModalOpen,
  appointment,
  isSmallScreen,
  isSchedulingOptional,
  openModal,
  widgetStyles,
  sendAmplitudeLogEvent,
}: SchedulerUIProps) => {
  const CALENDLY_MIN_WIDTH = 320;
  const CALENDLY_HEIGHT = 950; // Put back to 750 after calendly fixes their bug with hide_event_type_details=1
  const CALENDLY_POST_SUBMIT_HEIGHT = 550;

  const renderSchedulingForScreen = () =>
    !isSmallScreen ? (
      <InlineWidget
        pageSettings={{
          backgroundColor: colors["neutral-0"],
          primaryColor: colors["blue-600"],
          textColor: colors["neutral-1100"],
          hideGdprBanner: true,
        }}
        prefill={prefill}
        styles={{
          minWidth: `${CALENDLY_MIN_WIDTH}px`,
          height: `${
            appointment ? CALENDLY_POST_SUBMIT_HEIGHT : CALENDLY_HEIGHT
          }px`,
          ...widgetStyles,
        }}
        url={url}
      />
    ) : (
      <Flex direction="column" gap={3}>
        {callType === "cic" && (
          <DecorativeText
            typeScale="h1"
            text={messageTree.enrollment.intakeScheduling.providerVideoVisit}
          />
        )}
        <Button onPress={openModal} text={messages.toScheduleHeader} />
      </Flex>
    );

  const handleContinueWithoutScheduling = () => {
    if (sendAmplitudeLogEvent) {
      sendAmplitudeLogEvent(`Clicked EC Continue without Scheduling`);
    }
    onSkip();
  };

  return (
    <Flex gap={2} direction="column">
      <Flex
        gap={2}
        direction="column"
        backgroundColor="neutral-0"
        borderRadius="D"
        padding={4}
      >
        {isCallScheduled ? (
          confirmationComponent ? (
            confirmationComponent
          ) : (
            <>
              <HeadingText
                text={
                  messageTree.enrollment.intakeScheduling.intakeCallScheduled
                }
                size={3}
              />
              <BodyText
                text={
                  messageTree.enrollment.intakeScheduling
                    .prepareForCallFillForms
                }
                size="medium"
              />
              <Button
                onPress={onContinue}
                text={messageTree.common.navigation.continue}
              />
            </>
          )
        ) : (
          <>
            {callType === "ec" ? (
              <Flex direction="column" gap={3}>
                <DecorativeText
                  typeScale="h1"
                  text={
                    messageTree.enrollment.intakeScheduling.someoneOnYourSide
                  }
                />

                <BodyText
                  text={
                    isSchedulingOptional
                      ? messageTree.enrollment.intakeScheduling.ecCallPitch
                      : messageTree.enrollment.intakeScheduling.intakeCallPitch
                  }
                  size="medium"
                />
                {isSchedulingOptional && (
                  <>
                    <EnrollmentConsultationLiveNotification />
                    <Flex justify="center">
                      <Flex width={300} justify="center">
                        <Button
                          testID="continue-without-scheduling-button"
                          text={
                            messageTree.enrollment.intakeScheduling
                              .continueWithoutScheduling
                          }
                          variant="outline"
                          onPress={handleContinueWithoutScheduling}
                        />
                      </Flex>
                    </Flex>
                  </>
                )}
              </Flex>
            ) : null}
            <Flex direction="column" gap={2}>
              {showCalendlyError ? (
                <>
                  <CalendlyErrorNotification />
                  <Button
                    onPress={onSkip}
                    text={messageTree.common.navigation.continue}
                  />
                </>
              ) : (
                renderSchedulingForScreen()
              )}
              {root !== null && (
                <PopupModal
                  pageSettings={{
                    backgroundColor: colors["neutral-0"],
                    primaryColor: colors["blue-600"],
                    textColor: colors["neutral-1100"],
                    hideGdprBanner: true,
                  }}
                  rootElement={root}
                  prefill={prefill}
                  url={url}
                  onModalClose={closeModal}
                  open={isModalOpen}
                />
              )}
            </Flex>
            {!!appointment && (
              <Flex direction="column" justify="center">
                <Button
                  onPress={onContinue}
                  text={messageTree.common.navigation.continue}
                />
                {callType === "ec" && !isSchedulingOptional ? (
                  <EnrollmentConsultationLiveNotification />
                ) : null}
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};
