import { Linking } from "react-native";

import { isMobileBrowser } from "@virtahealth/utils";

import { epPost } from "../utils/ep_api";

export const onTextLinkPress = async (
  logEvent: (description: string) => void
) => {
  try {
    await epPost("/send-sms-app-download-link", {});
  } catch (e) {
    logEvent("Failed to text download app link");
  }
};

export const getDownloadAppUrl = () => {
  if (window.navigator.userAgent.match(/Android/i)) {
    return "https://play.google.com/store/apps/details?id=com.participantapp&hl=en_US&gl=US";
  } else {
    return "https://apps.apple.com/us/app/virta-health/id1164603213";
  }
};

export const textOrOpenAppDownloadLink = async (
  logEvent: (description: string) => void
) => {
  if (isMobileBrowser()) {
    Linking.openURL(getDownloadAppUrl());
    logEvent("Clicked download app link");
  } else {
    onTextLinkPress(logEvent);
    logEvent("Clicked text app link");
  }
};
