import { RequestOptions } from "@virtahealth/experiences";

import { formatBody, formatResponse } from "../utils/api_utils";
import { configureCSRF } from "../utils/csrf_utils";

import {
  BadRequestError,
  NotFoundError,
  ResponseError,
  ServerError,
  UnauthenticatedRequestError,
} from "./api_errors";

export type InternalFetchOptions<PayloadType> = RequestOptions & {
  method: "GET" | "POST" | "PATCH" | "PUT";
  endpoint: string;
  payload?: PayloadType;
  credentials?: RequestCredentials;
  mode?: RequestMode;
  refreshToken?: boolean;
};

export const internalFetch = async <PayloadType>(
  options: InternalFetchOptions<PayloadType>
) => {
  const {
    method,
    endpoint,
    credentials = "include",
    mode = "cors",
    headers = {},
    payload,
    signal: abortSignal,
    getResponseHeaders,
    getResponseStatus,
  } = options;

  let body = undefined;

  if (payload) {
    const isMultipart = payload instanceof FormData;

    if (isMultipart) {
      body = payload as unknown as FormData;
    } else {
      if (!headers["Content-Type"]) {
        headers["Content-Type"] = "application/json; charset=UTF-8";
      }

      body = formatBody(payload);
    }
  }

  return window
    .fetch(endpoint, {
      credentials,
      mode,
      method,
      headers: configureCSRF(headers),
      ...(body && { body }),
      ...(abortSignal && { signal: abortSignal }),
    })
    .then(async (response) => {
      if (!response.ok) {
        throw new ResponseError(response);
      }

      getResponseHeaders?.(response.headers);
      getResponseStatus?.(response.status, response.statusText);

      if (response.status === 204) {
        return {};
      }

      const mimetype = response.headers.get("Content-Type");

      if (mimetype?.includes("application/pdf")) {
        return response.blob();
      } else if (mimetype?.includes("application/json")) {
        return response.json();
      } else if (
        mimetype?.includes("text/plain") ||
        mimetype?.includes("/text/html")
      ) {
        return response.text();
      } else {
        // TODO: Preserving the status quo for now - this is probably raising some errors to Sentry
        return response.json();
      }
    })
    .then((data) => {
      if (data instanceof Blob) {
        return data;
      } else {
        return formatResponse(data);
      }
    })
    .catch((e) => {
      if (e instanceof ResponseError) {
        if (e.status == 400) {
          throw new BadRequestError(e.response);
        } else if (e.status == 401) {
          throw new UnauthenticatedRequestError(e.response);
        } else if (e.status == 404) {
          throw new NotFoundError(e.response);
        } else if (e.status >= 500) {
          throw new ServerError(e.response);
        } else {
          throw e;
        }
      } else if (e instanceof SyntaxError) {
        // Error parsing JSON
        throw e;
      } else {
        // Error with the Fetch API while making the request
        throw e;
      }
    });
};

export const get = async (endpoint: string, options?: RequestOptions) =>
  internalFetch({ method: "GET", endpoint, ...options });

export const patch = async <PayloadType>(
  endpoint: string,
  payload: PayloadType,
  options?: RequestOptions
) => internalFetch({ method: "PATCH", endpoint, payload, ...options });

export const put = async <PayloadType>(
  endpoint: string,
  payload: PayloadType,
  options?: RequestOptions
) => internalFetch({ method: "PUT", endpoint, payload, ...options });

export const post = async <PayloadType>(
  endpoint: string,
  payload: PayloadType,
  options?: RequestOptions
) => internalFetch({ method: "POST", endpoint, payload, ...options });
