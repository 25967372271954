import { isUndefined } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

import { VirtaContext, VirtaContextComponents } from "@virtahealth/components";
import { Spinner } from "@virtahealth/design-components";
import { EnrollmentFinalStepsScreen } from "@virtahealth/experiences";
import { base } from "@virtahealth/styles";

import PAModal from "../common-components/PAModal";
import { useEnrollmentContext } from "../contexts/enrollment";
import { LocaleContext } from "../contexts/locale";
import { useRequestContext } from "../contexts/request";
import { getEnv } from "../enrollment/utils";
import { useScreenViewEffect } from "../hooks/amplitudeEffects";
import { useGraphQLClient } from "../hooks/useGraphQLClient";
import { AnalyticsClient } from "../utils/analytics_client";
import { textOrOpenAppDownloadLink } from "../utils/app_download";
import { EPClient } from "../utils/ep_api";
import { PAClient } from "../utils/pa_client";

import styles from "./styles.css";

const NextStepsPage = () => {
  const enrollment = useEnrollmentContext();
  const { locale } = React.useContext(LocaleContext);
  const { push } = useHistory();
  const { epGet } = useRequestContext();
  const graphQLClient = useGraphQLClient();
  const { analyticsClient } = React.useContext(VirtaContextComponents);

  const { isRetrievingStatus, user } = enrollment;

  const env = getEnv();

  useScreenViewEffect("Final Steps");

  return isRetrievingStatus || isUndefined(graphQLClient) ? (
    <div className={styles.spinnerContainer}>
      <Spinner size="large" />
    </div>
  ) : (
    <div className={styles.finalStepsContainer}>
      <VirtaContext
        theme={base}
        client={new PAClient()}
        analyticsClient={new AnalyticsClient()}
        enrollmentClient={new EPClient()}
        gqlClient={graphQLClient}
        locale={locale}
        virtaId={user.virtaId}
      >
        <EnrollmentFinalStepsScreen
          enrollment={enrollment}
          env={env}
          epGet={epGet}
          locale={locale}
          // @ts-ignore modal type is not well defined
          ModalComponent={PAModal}
          pushLink={push}
          textOrOpenAppDownloadLink={() =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            textOrOpenAppDownloadLink(analyticsClient?.logCustomEvent!)
          }
        />
      </VirtaContext>
    </div>
  );
};

export default NextStepsPage;
