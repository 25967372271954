/** Error that can be thrown on an unsuccessful network response status.
 *
 * This error is thrown when a network request is made and the response status
 * is not in the 200 range. The error contains the response object, which
 * contains the status code, status text, and url.
 *
 * Currently, there are places in code where previously the Response object was
 * thrown instead of an explicit error - this has lead to a pattern of accessing
 * Response object properties directly in catch blocks. To be backwards compatible
 * with this pattern, this error class has properties that mirror the Response
 * object.
 *
 * In the future, we should explicitly handle different error types in catch
 * blocks because there may be other types of errors that can be thrown and
 * not all errors will have the same properties.
 */
export class ResponseError extends Error {
  response: Response;
  status: number;
  statusText: string;
  url: string;

  constructor(response: Response, message?: string) {
    super(message);

    this.name = "ResponseError";
    this.response = response;
    this.status = response.status;
    this.statusText = response.statusText;
    this.url = response.url;
  }
}

export class ServerError extends ResponseError {
  constructor(response: Response) {
    super(response, "Server error.");

    this.name = "ServerError.";
  }
}

export class UnauthenticatedRequestError extends ResponseError {
  constructor(response: Response) {
    super(response, "Unauthenticated request.");

    this.name = "UnauthenticatedRequestError";
  }
}

export class BadRequestError extends ResponseError {
  constructor(response: Response) {
    super(response, "Bad request.");

    this.name = "BadRequestError";
  }
}

export class NotFoundError extends ResponseError {
  constructor(response: Response) {
    super(response, "Not found.");

    this.name = "NotFoundError";
  }
}
