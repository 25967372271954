import React from "react";
import { useHistory } from "react-router-dom";

import { get as paGet } from "../charan_csrf_wrapper";
import gqlClient from "../gqlClient";
import { useEffectAsync } from "../hooks";
import { AnalyticsClient } from "../utils/analytics_client";
import {
  clearAuthFromStorage,
  getAccountType as getAccountTypeFromStorage,
  hasValidSession,
  setAccountType as setAccountTypeStorage,
} from "../utils/ep_api";
import { reportAnyError } from "../utils/errors";
import { batchSetState } from "../utils/state";

interface Props {
  auth: AuthContextType;
  children: React.ReactNode;
}

export type AccountType = "patient" | "applicant";

export const AuthProvider: React.FC<Props> = ({ auth, children }) => (
  <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
);

export const AuthContext = React.createContext<AuthContextType>(
  {} as unknown as AuthContextType
);

export const useAuth = () => React.useContext(AuthContext);

export const useProvideAuth = (
  analyticsClient: AnalyticsClient
): AuthContextType => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [accountType, setAccountTypeState] =
    React.useState<AccountType>("applicant");

  const { push } = useHistory();

  /** Guesses account type based on location.
   *
   * There seem to be some cases/paths where one isn't set in local storage, so this is temporary until
   * we've diagnosed and fixed the root cause.
   */
  const guessAccountType = (): AccountType => {
    if (
      window.location.pathname.startsWith("/enrollment") ||
      window.location.pathname.startsWith("/apply")
    ) {
      return "applicant";
    }

    return "patient";
  };

  const getAccountType = (): AccountType => {
    const accountType = getAccountTypeFromStorage();

    if (!accountType) {
      return guessAccountType();
    }

    return accountType;
  };

  const setAccountType = (accountType: AccountType) => {
    setAccountTypeState(accountType);
    setAccountTypeStorage(accountType);
  };

  const logIn = (accountType: AccountType) => {
    batchSetState(() => {
      setIsAuthenticated(true);
      setAccountType(accountType);
      setIsLoading(false);
    });
  };

  const logOut = async () => {
    try {
      await paGet("/user/logout");
    } catch (e: unknown) {
      reportAnyError(e);
    }

    clearAuthFromStorage();
    await gqlClient().then((client) => client?.resetStore());

    batchSetState(() => {
      setIsAuthenticated(false);
      setIsLoading(false);
    });

    push("/user/login");
  };

  useEffectAsync(async (signal: AbortSignal) => {
    if (await hasValidSession({ signal })) {
      analyticsClient.logCustomEvent(
        "Successful verification of existing session"
      );

      logIn(getAccountType());
    } else {
      await logOut();
    }
  }, []);

  return {
    accountType,
    isLoading,
    isAuthenticated,
    logIn,
    logOut,
  };
};

export type AuthContextType = {
  accountType: AccountType;
  isAuthenticated: boolean;
  isLoading: boolean;
  logIn: (accountType: AccountType, refreshToken?: string) => void;
  logOut: () => Promise<void>;
};
