import { RequestOptions } from "@virtahealth/experiences";

import { get } from "./api";

const PARTNER_API_SSO = window.env.partnerSSOApiUrl;

export const psGet = (endpoint: string, options?: RequestOptions) => {
  const endpointUrl = `${PARTNER_API_SSO}${endpoint}`;
  const optionsToPass = options ?? {};

  return get(endpointUrl, {
    ...optionsToPass,
  });
};
