import React from "react";

import { VirtaContextComponents } from "@virtahealth/components";
import { Flex, Margin } from "@virtahealth/design-layout";
import { ConvertToPatientScreen } from "@virtahealth/experiences";

import { useEnrollmentContext } from "../contexts/enrollment";
import { useScreenViewEffect } from "../hooks/amplitudeEffects";
import { textOrOpenAppDownloadLink } from "../utils/app_download";

export const ConvertToPatient = () => {
  useScreenViewEffect("Convert to Patient Page");

  const { analyticsClient } = React.useContext(VirtaContextComponents);

  const { user } = useEnrollmentContext();
  const preferredName = user.usualName ?? user.firstName;

  return (
    <Margin top={5} bottom={3}>
      <Flex
        direction="column"
        backgroundColor="white"
        maxWidth={575}
        padding={2}
        borderRadius="B"
      >
        <ConvertToPatientScreen
          preferredName={preferredName || ""}
          onPress={() =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            textOrOpenAppDownloadLink(analyticsClient?.logCustomEvent!)
          }
        />
      </Flex>
    </Margin>
  );
};

export default ConvertToPatient;
