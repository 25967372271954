import React from "react";

import { Spinner, SpinnerProps } from "@virtahealth/design-components";

import appStyles from "../../app/styles.css";

export const LoadingSpinner = ({ ...props }: SpinnerProps) => (
  <div className={appStyles.spinnerContainer}>
    <Spinner {...props} />
  </div>
);

export default LoadingSpinner;
