import React from "react";
import { useHistory } from "react-router-dom";

import { BodyText, Button } from "@virtahealth/design-components";
import { Row } from "@virtahealth/design-layout";
import { messages } from "@virtahealth/utils";

import { BaseNavBar } from "./components/BaseNavBar";
import { useNavBarBreakpoints } from "./utils";

const LOGIN_PATH = "/user/login";

export const UnauthenticatedNavBar = () => {
  const history = useHistory();
  const { isSmallScreen } = useNavBarBreakpoints();

  const { pathname } = history.location;
  const showLoginButton = pathname !== LOGIN_PATH;

  const navigateToLogin = () => {
    history.push("/user/login");
  };

  return (
    <BaseNavBar
      secondaryContent={
        showLoginButton && (
          <Row align="center" gap={2}>
            {!isSmallScreen && <BodyText text={messages.alreadyHaveAccount} />}
            <Button
              variant="outline"
              text={messages.logIn}
              onPress={navigateToLogin}
              testID="login-in-button"
            />
          </Row>
        )
      }
    />
  );
};
