import { get, isEmpty } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

import { Spinner } from "@virtahealth/design-components";
import {
  CalendlyEventType,
  DynamicForm,
  EnrollmentConsultationLiveNotification,
  SCHEDULE_HP_ACTION,
  getSchedulingUrl,
} from "@virtahealth/experiences";
import { DynamicFormSchema } from "@virtahealth/experiences/src/DynamicForm/types";

import { useEnrollmentContext } from "../contexts/enrollment";
import { useLocale } from "../contexts/locale";
import { useRequestContext } from "../contexts/request";
import { getEnv } from "../enrollment/utils";
import { useScreenViewEffect } from "../hooks/amplitudeEffects";
import { useGraphQLClient } from "../hooks/useGraphQLClient";
import { retrieveAndCompleteActions } from "../utils/actions_utils";

import { PatientCallScheduler } from "./PatientCallScheduler";
import styles from "./styles.css";

const HP_CALL_TYPE = "cic";
const ENROLLMENT_CONSULTATION_CALL_TYPE = "ec";
const QUALIFICATION_CALL_TYPE = "qc";

const ENV = getEnv();

interface ScheduleCallProps {
  callType: CalendlyEventType;
  actionName?: string;
  isSchedulingOptional?: boolean;
  setSkipCall?: (value: boolean) => void;
}

const abortController = new AbortController();

const ScheduleCall = ({
  callType,
  actionName,
  isSchedulingOptional = false,
  setSkipCall,
}: ScheduleCallProps) => {
  const history = useHistory();
  const {
    featureFlags,
    user,
    segments,
    scheduledQcCalls,
    scheduledEcCalls,
    scheduledHpCalls,
    updateEnrollmentStatus,
  } = useEnrollmentContext();
  const locale = useLocale();
  const { epGet } = useRequestContext();
  const [isFetchingSchema, setIsFetchingSchema] = React.useState(false);
  const [confirmationSchema, setConfirmationSchema] = React.useState<
    DynamicFormSchema | undefined
  >(undefined);
  const meetingUrl = getSchedulingUrl({
    applicantType: segments.applicantType,
    eventType: callType,
    localeString: locale.locale,
    user,
    env: ENV,
  });
  const graphQLClient = useGraphQLClient();

  React.useEffect(() => {
    const callTypeToScheduledCallsMap: Record<CalendlyEventType, object[]> = {
      [HP_CALL_TYPE]: scheduledHpCalls,
      [ENROLLMENT_CONSULTATION_CALL_TYPE]: scheduledEcCalls,
      [QUALIFICATION_CALL_TYPE]: scheduledQcCalls,
    };
    const scheduledCalls = callTypeToScheduledCallsMap[callType];
    if (scheduledCalls.length > 0) {
      getConfirmationSchema(
        getEventId((scheduledCalls.slice(-1)[0] as any).uri)
      );
    }
  }, []);

  const getEventId = (url: string) => url.split("/").slice(-1)[0];

  const getConfirmationSchema = async (eventId: string) => {
    setIsFetchingSchema(true);
    let confirmationSchema;
    try {
      confirmationSchema = await epGet(
        `/forms/intake-call-confirmation?event_id=${eventId}&event_type=${callType}`
      );
    } catch (e) {
      console.error("Error fetching confirmation schema", e);
      // There is an edge case here for EC where the EnrollmentRouter will route back to this component,
      // potentially causing a loop. We set skipCall here to prevent this
      setSkipCall?.(true);
      history.push("/enrollment");
      return;
    }
    if (abortController.signal.aborted) {
      return;
    }
    setConfirmationSchema(confirmationSchema);
    setIsFetchingSchema(false);
  };

  const onSetAppointment = async (appointmentToSet: any) => {
    /** Although enrollment actions are managed in the EP backend, actions related to call steps are
     * additionally completed in the frontend to mitigate issues calling Calendly, which the backend
     * relies on to determine call step statuses to complete actions. This ensures that call scheduling
     * actions are still completed when an applicant schedules a call.
     */
    if (graphQLClient && user && actionName) {
      retrieveAndCompleteActions(graphQLClient, user.virtaId, {
        actionContentId: get(window.env.enrollmentActions, actionName),
      });
    }

    const eventId = getEventId(appointmentToSet.data.payload.event.uri);

    getConfirmationSchema(eventId);
  };

  const onSkip = () => {
    setSkipCall?.(true);
    history.push("/enrollment");
  };

  const onDynamicFormSubmit = async () => {
    await updateEnrollmentStatus();
    history.push("/enrollment");
  };

  let screenName = "Unmatched Call Scheduler";

  if (callType === ENROLLMENT_CONSULTATION_CALL_TYPE) {
    screenName = "Enrollment Call Scheduler";
  } else if (callType === HP_CALL_TYPE) {
    screenName = "Clinical Intake Call Scheduler";
  } else if (callType === QUALIFICATION_CALL_TYPE) {
    screenName = "Qualification Call Scheduler";
  }

  useScreenViewEffect(screenName);

  if (isFetchingSchema) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner size="large" />
      </div>
    );
  }

  if (!isEmpty(confirmationSchema)) {
    return (
      <div className={styles.confirmation}>
        <DynamicForm
          onSubmit={onDynamicFormSubmit}
          isApiLoading={false}
          schema={confirmationSchema as DynamicFormSchema}
          page={0}
        />
        {callType === "ec" && !isSchedulingOptional ? (
          <EnrollmentConsultationLiveNotification />
        ) : null}
      </div>
    );
  }

  return (
    <div className={styles.intakeCallContainer}>
      <PatientCallScheduler
        url={decodeURIComponent(meetingUrl)}
        onSetAppointment={onSetAppointment}
        onContinue={() => {
          // do nothing - this function is used for a separate call confirmation component encapsulated in
          // PatientCallScheduler, however we are rendering our own call confirmation schema in this
          // component and handling the continue here.
          return null;
        }}
        onSkip={onSkip}
        callType={callType}
        isSchedulingOptional={isSchedulingOptional}
        showCalendlyError={featureFlags?.showCalendlyError}
      />
    </div>
  );
};

export const ScheduleHpCall = () => {
  return (
    <ScheduleCall callType={HP_CALL_TYPE} actionName={SCHEDULE_HP_ACTION} />
  );
};

export const ScheduleMandatoryEcCall = ({
  setSkipCall,
}: {
  setSkipCall: (value: boolean) => void;
}) => {
  return (
    <ScheduleCall
      callType={ENROLLMENT_CONSULTATION_CALL_TYPE}
      setSkipCall={setSkipCall}
    />
  );
};

export const ScheduleQcCall = () => {
  return <ScheduleCall callType={QUALIFICATION_CALL_TYPE} />;
};
