import React from "react";

import { Button } from "@virtahealth/design-components";

import { useLocale } from "../../contexts/locale";

const reverseLanguageMap: Record<string, string> = {
  en: "es",
  es: "en",
};

/*
This is a unique scenario where we don't actually want to translate,
i.e. when the user's language is English, we want to show the Spanish
translation of "Spanish", and vice versa. Creating these messages here
with the intention of falling back to the defaultMessage.
*/
const overrideMessages: Record<string, Record<string, string>> = {
  en: {
    id: "overrideEnglish",
    defaultMessage: "English",
    description: "The English language",
  },
  es: {
    id: "overrideSpanish",
    defaultMessage: "Español",
    description: "The Spanish language",
  },
};

const LanguageToggle: React.FC = () => {
  const { locale, setLocale } = useLocale();

  const reverseLanguage = reverseLanguageMap[locale];

  const onPress = () => {
    setLocale(reverseLanguage);
  };

  return (
    <Button
      onPress={onPress}
      variant="text"
      testID="language-toggle-button"
      text={overrideMessages[reverseLanguage]}
      before="network-user"
    />
  );
};

export default LanguageToggle;
