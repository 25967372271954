import * as Sentry from "@sentry/browser";
import React from "react";
import { useHistory } from "react-router-dom";

import {
  ColorfulBanner,
  VirtaContextComponents,
} from "@virtahealth/components";
import { Button, ProgressBar } from "@virtahealth/design-components";
import { DynamicForm, DynamicFormSchema } from "@virtahealth/design-forms";
import { Flex, Margin } from "@virtahealth/design-layout";
import { ProgressWText } from "@virtahealth/experiences";
import {
  VirtaIntlMessageOrString,
  getMessageFromTreeOrKey,
  messages,
} from "@virtahealth/utils";

import { useEnrollmentContext } from "../../contexts/enrollment";
import { useRequestContext } from "../../contexts/request";
import { useZendeskWidget } from "../../hooks/zendeskEffects";
import styles from "../styles.css";

import LoadingSpinner from "./LoadingSpinner";
import SmsDynamicForm from "./SmsDynamicForm";

interface FormValues {
  verificationCode: string;
}
interface Payload {
  verificationCode: string;
}

interface Props {
  progressPercentage?: number;
  progressCurrentStep?: number;
  progressTotalSteps?: number;
  showBanner?: boolean;
  displayPhoneNumber?: string;
  onVerificationSuccess?: () => void;
  successRedirectionPath?: string;
  logViewedEventProperties?: Record<string, string>;
}

interface SchemaResults {
  smsVerificationSuccess?: DynamicFormSchema;
  smsVerification?: DynamicFormSchema;
}

const useSmsVerification = (
  isAppRevamp = false,
  onVerificationSuccess?: () => void,
  successRedirectionPath = "/enrollment/basic-information"
) => {
  const { isPhoneVerified, updateEnrollmentStatus } = useEnrollmentContext();
  const [showVerified, setShowVerified] = React.useState(false);
  const { epPost } = useRequestContext();
  const history = useHistory();

  React.useEffect(() => {
    if (isPhoneVerified) {
      history.push("/enrollment");
    }
  }, [isPhoneVerified]);

  const onContinue = async (values: FormValues) => {
    const data: Payload = {
      verificationCode: values.verificationCode,
    };
    try {
      await epPost<Payload>("/auth/phone/verify/confirm", data);
      onVerificationSuccess?.();
      setShowVerified(true);
      if (isAppRevamp) {
        setTimeout(async () => {
          await updateEnrollmentStatus();
          history.push(successRedirectionPath);
        }, 3000);
      } else {
        await updateEnrollmentStatus();
        history.push(successRedirectionPath);
      }
    } catch (e) {
      if (e.status === 404) {
        throw messages.apiErrorMaintenance;
      }
      Sentry.captureException(e);
      throw messages.apiError;
    }
  };

  const onVerify = async () => {
    try {
      await epPost("/auth/phone/verify/start", {});
    } catch (e) {
      if (e.status === 404) {
        throw messages.apiErrorMaintenance;
      }
      Sentry.captureException(e);
      throw messages.apiError;
    }
  };

  return {
    onContinue,
    onVerify,
    isPhoneVerified,
    showVerified,
  };
};

export const SmsVerification = ({
  progressPercentage = 50,
  progressCurrentStep,
  progressTotalSteps,
  showBanner = true,
  displayPhoneNumber,
  onVerificationSuccess,
  successRedirectionPath,
  logViewedEventProperties,
}: Props) => {
  useZendeskWidget();
  const { analyticsClient } = React.useContext(VirtaContextComponents);
  const { user } = useEnrollmentContext();
  const history = useHistory();
  const { onContinue, onVerify, showVerified } = useSmsVerification(
    true,
    onVerificationSuccess,
    successRedirectionPath
  );

  const [schemas, setSchemas] = React.useState<SchemaResults>({});
  const [loadingSchema, setLoadingSchema] = React.useState(true);

  const { epPost } = useRequestContext();

  const fetchSchema = async () => {
    try {
      const phoneNumber = displayPhoneNumber || user.primaryPhone;
      const schemas = await epPost(`/forms/sms-verification`, {
        phone_number: phoneNumber,
      });
      setSchemas(schemas);
      setLoadingSchema(false);
    } catch (error) {
      Sentry.captureException(error);
      throw messages.apiError;
    }
  };
  React.useEffect(() => {
    fetchSchema();

    analyticsClient?.logViewEvent("SMS Verification", {
      screen_name: "SMS Verification",
      screen_path: window.location.pathname,
      url: window.location.href,
      ...logViewedEventProperties,
    });
  }, []);

  const [error, setError] = React.useState<VirtaIntlMessageOrString | null>(
    null
  );
  const [isResending, setIsResending] = React.useState(false);

  const handleResend = async () => {
    setError(null);
    setIsResending(true);
    try {
      await onVerify();
    } catch (err) {
      Sentry.captureException(err);
      setError(err as VirtaIntlMessageOrString);
    } finally {
      setIsResending(false);
    }
  };

  const VerificationContainer = ({
    children,
  }: {
    children: React.ReactNode;
  }) => (
    <div className={styles.smsVerificationContainer}>
      <Flex direction="column" testID="verification-container">
        {children}
      </Flex>
    </div>
  );

  if (loadingSchema) {
    return <LoadingSpinner />;
  }

  if (showVerified) {
    return (
      <Flex direction="column" paddingTop={4}>
        <ProgressWText percent={60} />
        <VerificationContainer>
          <Margin top={-5}>
            <DynamicForm
              onSubmit={() => new Promise<void>((r) => r())}
              schema={
                {
                  ...schemas.smsVerificationSuccess,
                  pages: schemas?.smsVerificationSuccess?.pages.map((page) => {
                    // @ts-ignore epGet/Post conversion to camelCase changes testID to testId, change it back
                    if (!page.testId) {
                      return page;
                    }
                    return {
                      ...page,
                      // @ts-ignore epGet/Post conversion to camelCase changes testID to testId, change it back
                      testID: page.testId,
                    };
                  }),
                } as DynamicFormSchema
              }
              page={0}
              isApiLoading={false}
            />
          </Margin>
        </VerificationContainer>
      </Flex>
    );
  }

  return (
    <Flex direction="column" paddingTop={4}>
      {Number.isInteger(progressCurrentStep) && progressTotalSteps ? (
        <ProgressBar
          currentStep={progressCurrentStep}
          totalSteps={progressTotalSteps}
          variant="withText"
        />
      ) : (
        <ProgressWText percent={progressPercentage} />
      )}

      <VerificationContainer>
        <Margin top={-5}>
          <SmsDynamicForm
            error={
              error
                ? "enrollment.smsVerification.failedToSendSmsVerification"
                : undefined
            }
            schema={schemas?.smsVerification as DynamicFormSchema}
            page={0}
            onSubmit={onContinue}
            isApiLoading={false}
            onUnmount={() => null}
          />
        </Margin>
        <Button
          variant="text"
          text={getMessageFromTreeOrKey(
            "enrollment.smsVerification.resendVerificationCodeSmsVerification"
          )}
          onPress={handleResend}
          testID="resend-verification-code"
          state={isResending ? "pending" : "default"}
        />
        <Button
          variant="text"
          text={getMessageFromTreeOrKey(
            "enrollment.smsVerification.editYourPhoneNumberSmsVerification"
          )}
          onPress={() => {
            history.push("/enrollment/sms-update");
          }}
          testID="edit-phone-number"
        />
        {showBanner && (
          <ColorfulBanner
            title={getMessageFromTreeOrKey(
              "enrollment.smsVerification.startNewChapter"
            )}
            description={getMessageFromTreeOrKey(
              "enrollment.smsVerification.startNewChapterDescription"
            )}
          />
        )}
      </VerificationContainer>
    </Flex>
  );
};

export default SmsVerification;
