import * as Sentry from "@sentry/browser";
import { isEmpty } from "lodash";
import React from "react";
import { FieldValues } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { VirtaContextComponents } from "@virtahealth/components";
import { Spinner } from "@virtahealth/design-components";
import { ENROLLMENT_SIGN_UP_FLOW } from "@virtahealth/experiences/src/EnrollmentForms/utils/ld_flag_constants";

import { AccountCreation } from "../account_creation";
import { BIFirstAccountCreationFlow } from "../account_creation/experiments/biFirstFlow";
import { BI_FIRST, CONTROL } from "../account_creation/experiments/variations";
import { identifyUserProperties } from "../utils/analytics_client";
import { psGet } from "../utils/partner_sso_api";

import {
  AnalyticsUserProperties,
  EMPTY_PERSON_ATTRIBUTES,
  LinkedAccountIds,
  MarketingAdIds,
  PersonAttributes,
} from "./types";

const SIGN_UP_SESSION_ID = "sign_up_session_id";

export const AccountCreationOrchestrator: React.FC = () => {
  const { launchDarkly } = React.useContext(VirtaContextComponents);
  const [signUpForm, setSignUpForm] = React.useState<string | null>();
  const [sessionId, setSessionId] = React.useState<string>("");

  const search = useLocation().search;
  const params = React.useMemo(() => new URLSearchParams(search), [search]);
  const { employerIdParam, ssoPartner, isFromSSO, marketingAdIds } =
    React.useMemo(() => {
      const employerIdParam = params.get("employer_id") || undefined;
      const marketingAdIds: MarketingAdIds = {
        google: params.get("gclid") || undefined,
        facebook: params.get("fbclid") || undefined,
      };

      const ssoPartner = params.get("sso_partner") ?? undefined;
      const isFromSSO = Boolean(ssoPartner);

      return {
        employerIdParam,
        isFromSSO,
        ssoPartner,
        marketingAdIds,
      };
    }, [params]);

  const [personAttributes, setPersonAttributes] = React.useState<
    PersonAttributes | FieldValues
  >(isFromSSO ? {} : EMPTY_PERSON_ATTRIBUTES);

  // INITIALIZING THE SESSION_ID FOR THE ANONYMOUS USER
  React.useEffect(() => {
    if (!sessionId) {
      const retrievedSessionId = localStorage.getItem(SIGN_UP_SESSION_ID);
      if (retrievedSessionId) {
        setSessionId(retrievedSessionId);
        return;
      }
      let generatedSessionId = "";
      try {
        generatedSessionId = self.crypto.randomUUID();
      } catch (e) {
        console.error("Failed to generate random UUID", e);
        generatedSessionId = `unknown-${new Date().getTime()}`;
      }
      localStorage.setItem(SIGN_UP_SESSION_ID, generatedSessionId);
      setSessionId(generatedSessionId);
    }
  }, [sessionId, setSessionId]);

  // FETCHING THE FLAG VALUE FOR THE SIGN UP FLOW, KEYED BY SESSION_ID
  React.useEffect(() => {
    const loadFlag = async () => {
      if (sessionId) {
        await launchDarkly?.identify(sessionId, {
          employer_id_from_url: employerIdParam || "undefined",
        });

        const signUpFlagEvaluation = await launchDarkly?.getStringFlag(
          ENROLLMENT_SIGN_UP_FLOW,
          "control"
        );

        setSignUpForm(signUpFlagEvaluation);
      }
    };
    loadFlag();
  }, [launchDarkly, employerIdParam, sessionId]);

  // FETCHING THE USER ATTRIBUTES FROM SSO
  React.useEffect(() => {
    const abortController = new AbortController();

    if (isFromSSO) {
      (async () => {
        let response = null;
        try {
          response = await psGet("/saml/attributes", {
            signal: abortController.signal,
          });
        } catch (e) {
          Sentry.captureException(e);
        }

        setPersonAttributes(response || EMPTY_PERSON_ATTRIBUTES);
      })();
    }

    return () => {
      abortController.abort();
    };
  }, [isFromSSO, ssoPartner]);

  // IDENTIFY USER PROPERTIES FOR ANALYTICS
  React.useEffect(() => {
    const userProperties: AnalyticsUserProperties = {};

    if (employerIdParam) {
      userProperties["deployment"] = employerIdParam;
    }
    if (ssoPartner) {
      userProperties["sso_partner"] = ssoPartner;
    }

    if (!isEmpty(userProperties)) {
      identifyUserProperties(userProperties);
    }
  }, [employerIdParam, ssoPartner]);

  const getLinkedAccountIds = React.useCallback(() => {
    const linkedAccountProvider = isFromSSO
      ? personAttributes?.linkedAccountProvider
      : params.get("la_p") || undefined;
    const linkedAccountId = isFromSSO
      ? personAttributes?.linkedAccountId
      : params.get("la_id") || undefined;

    const linkedAccountIds: LinkedAccountIds = {};
    if (linkedAccountProvider && linkedAccountId) {
      linkedAccountIds[linkedAccountProvider] = linkedAccountId;
    }

    if (marketingAdIds.google) {
      linkedAccountIds["googleclid"] = marketingAdIds.google;
    }

    if (marketingAdIds.facebook) {
      linkedAccountIds["facebookclid"] = marketingAdIds.facebook;
    }

    return linkedAccountIds;
  }, [isFromSSO, params, personAttributes, marketingAdIds]);

  if (signUpForm === CONTROL) {
    return (
      <AccountCreation
        employerIdParam={employerIdParam}
        personAttributes={personAttributes}
        getLinkedAccountIds={getLinkedAccountIds}
      />
    );
  } else if (signUpForm === BI_FIRST) {
    return (
      <BIFirstAccountCreationFlow
        sessionId={sessionId}
        employerId={employerIdParam || null}
        personAttributes={personAttributes}
        getLinkedAccountIds={getLinkedAccountIds}
      />
    );
  } else {
    return <Spinner />;
  }
};

export default AccountCreationOrchestrator;
