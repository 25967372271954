import React from "react";
import { FieldValues } from "react-hook-form";
import { Dimensions } from "react-native";
import { useHistory } from "react-router-dom";

import { Stack } from "@virtahealth/design-layout";
import { useRequestContext } from "@virtahealth/experiences";
import { useBool } from "@virtahealth/hooks";
import {
  ANALYTICS_EVENTS,
  BIFirstAccountCreation,
  BIFirstAccountCreationCompleted,
} from "@virtahealth/participant-app";

import {
  LinkedAccountIds,
  PersonAttributes,
} from "../../../account_creation_orchestrator/types";
import {
  MAX_FORM_WIDTH,
  MAX_HORIZONTAL_FORM_WIDTH,
} from "../../../constants/formSizes";
import { useAuth } from "../../../contexts/auth";
import { LocaleContext } from "../../../contexts/locale";
import { useZendeskWidget } from "../../../hooks/zendeskEffects";
import { SmsVerification } from "../../../sms_verification";
import { reportAnyError } from "../../../utils/errors";

import {
  logClickedBackOnBasicInformationPage,
  logClickedBackOnShortenedAccountCreationPage,
  logClickedCTAOnBasicInformationPage,
  logClickedCTAOnSMSVerificationPage,
  logClickedCTAOnShortenedAccountCreationPage,
  logClickedEditResponsesOnDisqualifiedPage,
  logClickedSignUpStarted,
  logViewedDisqualifiedPage,
  logViewedLeadCapturePage,
  logViewedSMSVerificationProperties,
  logViewedSignUpConfirmation,
} from "./analytics";
import {
  _buildCompleteBasicInformationPayload,
  _buildCreateAccountPayload,
} from "./utils";

const { width: screenWidth } = Dimensions.get("window");
const MAX_WIDTH =
  screenWidth >= MAX_HORIZONTAL_FORM_WIDTH + 100
    ? MAX_HORIZONTAL_FORM_WIDTH
    : MAX_FORM_WIDTH;

interface Props {
  sessionId: string;
  employerId: string | null;
  personAttributes: PersonAttributes | FieldValues;
  getLinkedAccountIds: () => LinkedAccountIds;
}

export const BIFirstAccountCreationFlow: React.FC<Props> = ({
  sessionId,
  employerId,
  personAttributes,
  getLinkedAccountIds,
}: Props) => {
  useZendeskWidget();

  const history = useHistory();
  const { epPost, epPatch } = useRequestContext();
  const { locale } = React.useContext(LocaleContext);
  const auth = useAuth();

  const [createAccountErrorMessage, setCreateAccountErrorMessage] =
    React.useState<string>("");
  const [isLoadingCreateAccount, setIsLoadingCreateAccount] = useBool(false);
  const [isFormCompleted, setIsFormCompleted] = useBool(false);
  const [createdVirtaId, setCreatedVirtaId] = React.useState<string>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [isSmsVerified, setIsSmsVerified] = useBool(false);

  React.useEffect(() => {
    logViewedLeadCapturePage(sessionId);
  }, [sessionId]);

  const submitCreateAccount = async (formData: FieldValues) => {
    setIsLoadingCreateAccount.on();
    const caPayload = _buildCreateAccountPayload(
      formData,
      employerId,
      personAttributes,
      locale,
      getLinkedAccountIds()
    );
    const eqPayload = _buildCompleteBasicInformationPayload(
      formData,
      employerId,
      locale
    );
    try {
      const createAccountResponse = await epPost("/users", {
        ...caPayload,
      });
      const virtaId = createAccountResponse.virtaId;

      setCreatedVirtaId(virtaId);
      setPhoneNumber(formData["phone"]);
      setFirstName(formData["firstName"]);

      auth.logIn("applicant");

      // There is some logic inside PATCH/eq that isn't in POST/users
      // Doing this "redundant" PATCH request to bring experimental flow up to parity with control
      await epPatch("/applicant_tasks/early_qualification", eqPayload);

      setIsFormCompleted.on();

      setIsLoadingCreateAccount.off();
    } catch (e) {
      setIsLoadingCreateAccount.off();
      setCreateAccountErrorMessage(
        "Could not create account. Please try again or contact support if this continues."
      );
      reportAnyError(e);
      throw e; // Handled by the DynamicForm - renders an error notification
    }
  };

  const analyticsEventHandlers = (eventName: ANALYTICS_EVENTS) => {
    if (eventName === "submitButton:leadCapture") {
      logClickedSignUpStarted(sessionId);
    } else if (eventName === "submitButton:medicalEligibility") {
      logClickedCTAOnBasicInformationPage(sessionId);
    } else if (eventName === "submitButton:accountCreation") {
      logClickedCTAOnShortenedAccountCreationPage(sessionId);
    } else if (eventName === "backButton:medicalEligibility") {
      logClickedBackOnBasicInformationPage(sessionId);
    } else if (eventName === "backButton:accountCreation") {
      logClickedBackOnShortenedAccountCreationPage(sessionId);
    } else if (eventName === "disqualified:loaded") {
      logViewedDisqualifiedPage(sessionId);
    } else if (eventName === "disqualified:clickedEditApplication") {
      logClickedEditResponsesOnDisqualifiedPage(sessionId);
    }
  };

  return (
    <Stack gap={2} width="100%" maxWidth={MAX_WIDTH} align="center" padding={2}>
      {!isFormCompleted && (
        <BIFirstAccountCreation
          sessionId={sessionId}
          employerId={employerId}
          shouldDisplaySmsOptIn={true} // TODO: remove this from PA monorepo
          redirectToLogin={() => history.push("/user/login")}
          openZendeskNewRequest={() => {
            window.open(
              "https://virtahealth.zendesk.com/hc/en-us/requests/new"
            );
          }}
          submitCreateAccount={submitCreateAccount}
          onAnalyticsEvent={analyticsEventHandlers}
          createAccountErrorMessage={createAccountErrorMessage}
          isLoadingCreateAccount={isLoadingCreateAccount}
        />
      )}

      {isFormCompleted && !isSmsVerified && (
        <SmsVerification
          progressCurrentStep={4}
          progressTotalSteps={4}
          showBanner={false}
          displayPhoneNumber={phoneNumber}
          onVerificationSuccess={() => {
            logClickedCTAOnSMSVerificationPage(sessionId, createdVirtaId);
            setIsSmsVerified.on();
          }}
          successRedirectionPath="/enrollment"
          logViewedEventProperties={logViewedSMSVerificationProperties(
            sessionId,
            createdVirtaId
          )}
        />
      )}
      {isFormCompleted && isSmsVerified && (
        <BIFirstAccountCreationCompleted
          firstName={firstName}
          onLoaded={() => {
            logViewedSignUpConfirmation(sessionId, createdVirtaId);
          }}
        />
      )}
    </Stack>
  );
};

export default BIFirstAccountCreationFlow;
