import { head, includes, isEqual, split } from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";

export const useLocale = () => React.useContext(LocaleContext);

interface Props {
  children: React.ReactNode;
}

export const LocaleProvider: React.FC<Props> = ({ children }) => {
  const Locale = useProvideLocale();
  return (
    <LocaleContext.Provider value={Locale}>{children}</LocaleContext.Provider>
  );
};

export const useProvideLocale = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const paramLang =
    params.get("lang") === "es" || params.get("lang") === "en"
      ? params.get("lang")
      : null;
  const initialLocale =
    includes(document.referrer, "es.virtahealth") ||
    isEqual(head(split(navigator.language, "-")), "es")
      ? "es"
      : "en";

  const [locale, setLocale] = React.useState(paramLang || initialLocale);
  return {
    locale,
    setLocale,
  };
};

type LocaleContextType = {
  locale: string;
  setLocale: (val: string) => void;
};

export const LocaleContext = React.createContext<LocaleContextType>({
  locale: "en",
  setLocale: () => undefined,
});
