export interface PersonAttributes {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  memberId: string | null;
  groupId: string | null;
  linkedAccountId: string;
  linkedAccountProvider: string;
}

export interface UsersEndpointData {
  earlyQualificationTask: {
    isComplete: boolean;
    isPayingOutOfPocket: boolean;
    referrerUrl: string;
    employerId: string | undefined;
  };
  user: {
    dateOfBirth: string;
    email: string;
    firstName: string;
    lastName: string;
    linkedAccountIds: { [x: string]: string } | undefined;
    password: string;
    primaryEmail: string;
    primaryPhone: string;
    tosAccepted: boolean;
  };
  coverage: {
    memberId?: string | null;
    groupId?: string | null;
  };
  privacyPracticesAccepted: boolean;
  smsOptInAccepted?: boolean;
}

export interface MarketingAdIds {
  google: string | undefined;
  facebook: string | undefined;
}

export type LinkedAccountIds = Record<string, string>;

export interface AnalyticsUserProperties {
  deployment?: string;
  sso_partner?: string;
}

export const EMPTY_PERSON_ATTRIBUTES: PersonAttributes = {
  firstName: "",
  lastName: "",
  email: "",
  dateOfBirth: "",
  memberId: null,
  groupId: null,
  linkedAccountId: "",
  linkedAccountProvider: "",
};
