import { get, omitBy, transform } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

import { VirtaContextComponents } from "@virtahealth/components";
import { Margin, Stack } from "@virtahealth/design-layout";
import { ProgressWText } from "@virtahealth/experiences";
import { ManagedDynamicForm } from "@virtahealth/participant-app";

import { DTP_DEPLOYMENT_KEY } from "../constants/Enrollment";
import { MAX_FORM_WIDTH } from "../constants/formSizes";
import { useAuth } from "../contexts/auth";
import { useEnrollmentContext } from "../contexts/enrollment";
import { LocaleContext } from "../contexts/locale";
import { useRequestContext } from "../contexts/request";
import { sendAmplitudeScreenViewEvent } from "../hooks/amplitudeEffects";
import { getSchemaV2 } from "../hooks/schema";
import { useZendeskWidget } from "../hooks/zendeskEffects";
import { UnauthenticatedRequestError } from "../utils/api_errors";
import { reportAnyError } from "../utils/errors";

import { convertStringBooleans } from "./utils";

const BasicInformation = () => {
  useZendeskWidget();

  const auth = useAuth();
  const enrollment = useEnrollmentContext();
  const {
    isPhoneVerified,
    opsTasks,
    setHasJustSubmittedBI,
    updateEnrollmentStatus,
    enrollmentSteps,
  } = enrollment;
  const history = useHistory();
  const { epPatch } = useRequestContext();
  const { analyticsClient } = React.useContext(VirtaContextComponents);
  const { locale } = React.useContext(LocaleContext);
  const [currentPage, setCurrentPage] = React.useState(0);

  const getSchema = getSchemaV2("basic-info");

  const onSubmit = async (values: any, isLastPage: boolean) => {
    try {
      await epPatch("/applicant_tasks/early_qualification", {
        employer_id: opsTasks.deployment.deploymentKey || DTP_DEPLOYMENT_KEY,
        ...transform(
          omitBy(values, (val) => val === ""),
          convertStringBooleans,
          {}
        ),
        is_complete: isLastPage,
        locale,
      });

      // this is a work around to control the progress tracker for now
      if (!isLastPage) {
        setCurrentPage(1);
      }
    } catch (e: unknown) {
      if (e instanceof UnauthenticatedRequestError) {
        // TODO: perhaps do this in a more generic location if it applies to all /enrollment routes
        await auth.logOut();
      } else {
        // Handled by the DynamicForm - renders and error notification
        throw e;
      }
    }
  };
  const onComplete = async () => {
    setHasJustSubmittedBI(true);
    try {
      await updateEnrollmentStatus();
      history.push("/enrollment/enrollment-status");
    } catch (e) {
      reportAnyError(e);
    }
  };

  const percent = get({ 0: 60, 1: 80 }, currentPage, 100);

  React.useEffect(() => {
    if (enrollmentSteps.basicInformation === "completed" || !isPhoneVerified) {
      history.push("/enrollment/enrollment-status");
    }
  }, [isPhoneVerified, enrollmentSteps.basicInformation, history]);

  return (
    <Stack gap={4} width="100%" maxWidth={MAX_FORM_WIDTH} padding={2}>
      <Margin horizontal={2}>
        <ProgressWText percent={percent} />
      </Margin>

      <ManagedDynamicForm
        queryKey="basic-information"
        schemaCall={getSchema}
        onSubmit={onSubmit}
        onCompleted={onComplete}
        onLogEvent={(eventTitle: string) => {
          sendAmplitudeScreenViewEvent(analyticsClient)(eventTitle);
        }}
      />
    </Stack>
  );
};

export default BasicInformation;
