import { VirtaClient } from "@virtahealth/components";
import { RequestOptions } from "@virtahealth/experiences";

import { AccountType } from "../contexts/auth";
import { InternalFetchOptions, get, patch, post } from "../utils/api";

import { UnauthenticatedRequestError } from "./api_errors";

export const SESSION_STORAGE_ACCOUNT_TYPE = "virta-account-type";

export const getAccountType = (): AccountType => {
  return (sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_TYPE) ||
    localStorage.getItem(SESSION_STORAGE_ACCOUNT_TYPE)) as AccountType;
};

export const setAccountType = (accountType: AccountType) => {
  localStorage.setItem(SESSION_STORAGE_ACCOUNT_TYPE, accountType);
};

export const clearAuthFromStorage = () => {
  [sessionStorage, localStorage].forEach((storage: Storage) => {
    storage.removeItem(SESSION_STORAGE_ACCOUNT_TYPE);
  });
};

function buildEndpointUrl(endpoint: string) {
  const epApiUrl = endpoint.startsWith("api")
    ? window.env.enrollmentPortalApiUrl
    : window.env.enrollmentPortalApiUrlV1;

  return `${epApiUrl ?? ""}${endpoint}`;
}

export const epFetch = <PayloadType>(
  options: InternalFetchOptions<PayloadType>
) => {
  const { method, endpoint, payload = {}, ...requestOptions } = options;

  const abortController = new AbortController();
  const epEndpoint = buildEndpointUrl(endpoint);
  const epRequestOptions: RequestOptions = {
    ...requestOptions,
    signal: abortController.signal,
  };

  let _epFetch: Promise<PayloadType>;

  switch (method) {
    case "GET":
      _epFetch = get(epEndpoint, epRequestOptions);
      break;
    case "PATCH":
      _epFetch = patch(epEndpoint, payload, epRequestOptions);
      break;
    case "POST":
      _epFetch = post(epEndpoint, payload, epRequestOptions);
      break;
    default:
      throw new Error(`Unsupported EP API method: ${method}`);
  }

  return _epFetch.catch((e) => {
    if (abortController.signal.aborted) {
      // Don't raise abort errors
    } else if (e instanceof UnauthenticatedRequestError) {
      // Clear auth and raise error to callers for handling
      clearAuthFromStorage();
      throw e;
    } else {
      // Raise error to callers for handling
      throw e;
    }
  });
};

export const epGet = (endpoint: string, options?: RequestOptions) =>
  epFetch({ method: "GET", endpoint, ...options });

export const epPatch = <PayloadType>(
  endpoint: string,
  payload: PayloadType,
  options?: RequestOptions
) =>
  epFetch({
    method: "PATCH",
    endpoint,
    payload,
    ...options,
  });

export const epPost = <PayloadType>(
  endpoint: string,
  payload: PayloadType,
  options?: RequestOptions
) => epFetch({ method: "POST", endpoint, payload, ...options });

export class EPClient implements VirtaClient {
  public get(url: string) {
    return epGet(url).then((response: any) => {
      return response;
    });
  }

  public patch<PayloadType>(url: string, body: PayloadType) {
    return epPatch(url, body).then((response: any) => {
      return response;
    });
  }

  public post<PayloadType>(url: string, body: PayloadType) {
    return epPost(url, body).then((response: any) => {
      return response;
    });
  }

  public delete<PayloadType>() {
    return Promise.resolve({} as PayloadType);
  }
}

export const hasValidSession = async (options: {
  signal: AbortSignal;
}): Promise<boolean> => {
  const { signal } = options;

  try {
    await epGet("/users/self", { signal });

    return true;
  } catch (e) {
    if (e instanceof UnauthenticatedRequestError) {
      return false;
    } else {
      throw e;
    }
  }
};
