import React from "react";
import { useHistory } from "react-router-dom";

import { VirtaContextComponents } from "@virtahealth/components";
import { Margin } from "@virtahealth/design-layout";
import { DownloadAppAOScreen } from "@virtahealth/experiences";
import { isMobileBrowser } from "@virtahealth/utils";

import { useEnrollmentContext } from "../contexts/enrollment";
import { useScreenViewEffect } from "../hooks/amplitudeEffects";
import { textOrOpenAppDownloadLink } from "../utils/app_download";

interface Props {
  setContinueInWeb: (continueInWeb: boolean) => void;
}

export const DownloadAppPage = ({ setContinueInWeb }: Props) => {
  const history = useHistory();
  const { analyticsClient } = React.useContext(VirtaContextComponents);
  const { user, segments } = useEnrollmentContext();

  const onContinue = () => {
    setContinueInWeb(true);
    history.push("/enrollment/next-steps");
  };

  const logDownloadApp = () => {
    analyticsClient?.logCustomEvent("Clicked download app");
  };

  useScreenViewEffect("Download App Page");

  return (
    <Margin top={5} bottom={3}>
      <DownloadAppAOScreen
        isMobile={isMobileBrowser()}
        firstName={user.firstName || ""}
        logDownloadApp={logDownloadApp}
        onPrimaryPress={() =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          textOrOpenAppDownloadLink(analyticsClient?.logCustomEvent!)
        }
        onContinue={onContinue}
        segments={segments}
      />
    </Margin>
  );
};

export default DownloadAppPage;
