/* eslint-disable max-lines */
import { assign, find, get } from "lodash";
import * as React from "react";

import {
  EnrollmentContext,
  EnrollmentContextDefaults,
  LabRequirementType,
  User,
} from "@virtahealth/experiences";
import {
  getCoverages,
  getScheduledCalls,
} from "@virtahealth/experiences/src/EnrollmentForms/contexts/api";
import {
  EnrollmentContextType,
  EnrollmentSteps,
  OpsTask,
  Segments,
  UpdateEnrollmentStatusOptions,
} from "@virtahealth/experiences/src/EnrollmentForms/contexts/types";
import { messages } from "@virtahealth/utils";

import { useRequestContext } from "../contexts/request";
import { ResponseError } from "../utils/api_errors";
import { reportAnyError } from "../utils/errors";

import { useLocale } from "./locale";

export { useEnrollmentContext } from "@virtahealth/experiences";

export const EnrollmentProvider: React.FC = ({ children }) => {
  const enrollment = useProvideEnrollment();
  return (
    <EnrollmentContext.Provider value={enrollment}>
      {children}
    </EnrollmentContext.Provider>
  );
};

export const useProvideEnrollment = (): EnrollmentContextType => {
  const [earlyQualification, setEarlyQualification] = React.useState<
    EnrollmentContextType["earlyQualification"]
  >(EnrollmentContextDefaults.earlyQualification);
  const [healthHistory, setHealthHistory] = React.useState<
    EnrollmentContextType["healthHistory"]
  >(EnrollmentContextDefaults.healthHistory);
  const [enrollmentStatus, setEnrollmentStatus] = React.useState<
    EnrollmentContextType["enrollmentStatus"]
  >(EnrollmentContextDefaults.enrollmentStatus);
  const [opsTasks, setOpsTasks] = React.useState<
    EnrollmentContextType["opsTasks"]
  >(EnrollmentContextDefaults.opsTasks);
  const [scheduledHpCalls, setScheduledHpCalls] = React.useState<
    EnrollmentContextType["scheduledHpCalls"]
  >(EnrollmentContextDefaults.scheduledHpCalls);
  const [scheduledEcCalls, setScheduledEcCalls] = React.useState<
    EnrollmentContextType["scheduledEcCalls"]
  >(EnrollmentContextDefaults.scheduledEcCalls);
  const [scheduledQcCalls, setScheduledQcCalls] = React.useState<
    EnrollmentContextType["scheduledQcCalls"]
  >(EnrollmentContextDefaults.scheduledQcCalls);
  const [user, setUser] = React.useState<EnrollmentContextType["user"]>(
    EnrollmentContextDefaults.user
  );
  const [isPhoneVerified, setIsPhoneVerified] = React.useState<
    EnrollmentContextType["isPhoneVerified"]
  >(EnrollmentContextDefaults.isPhoneVerified);
  const [hasJustSubmittedBI, setHasJustSubmittedBI] = React.useState<
    EnrollmentContextType["hasJustSubmittedBI"]
  >(EnrollmentContextDefaults.hasJustSubmittedBI);
  const [hasRequiredActions, setHasRequiredActions] = React.useState<
    EnrollmentContextType["hasRequiredActions"]
  >(EnrollmentContextDefaults.hasRequiredActions);

  const [featureFlags, setFeatureFlags] = React.useState<
    EnrollmentContextType["featureFlags"]
  >(EnrollmentContextDefaults.featureFlags);
  const [isRetrievingStatus, setIsRetrievingStatus] = React.useState<
    EnrollmentContextType["isRetrievingStatus"]
  >(EnrollmentContextDefaults.isRetrievingStatus);
  const [isContextLoaded, setIsContextLoaded] = React.useState<
    EnrollmentContextType["isContextLoaded"]
  >(EnrollmentContextDefaults.isContextLoaded);
  const [labsRequirement, setLabsRequirement] = React.useState<
    EnrollmentContextType["labsRequirement"]
  >(EnrollmentContextDefaults.labsRequirement);
  const [segments, setSegments] = React.useState<
    EnrollmentContextType["segments"]
  >(EnrollmentContextDefaults.segments);
  const [coverages, setCoverages] = React.useState<
    EnrollmentContextType["coverages"]
  >(EnrollmentContextDefaults.coverages);
  const [enrollmentSteps, setEnrollmentSteps] = React.useState<
    EnrollmentContextType["enrollmentSteps"]
  >(EnrollmentContextDefaults.enrollmentSteps);

  const { setLocale } = useLocale();
  const { epGet } = useRequestContext();

  // For T1D DQ applicants, we handle their employer eligibility specially because of legacy behavior.
  // Ideally, this would be encapsulated in segments.employerEligibility itself however there's some
  // work to be done to make that happen. For now, we'll just handle it here.
  // See: https://virtahealth.slack.com/archives/G01D867NDRN/p1697662171778169
  const getIsDQed = () =>
    enrollmentStatus.enrollmentIndicator === "red" &&
    enrollmentStatus.disqualifications &&
    enrollmentStatus.disqualifications.length;
  const getIsPrelaunch = () => opsTasks.deployment.isPrelaunch;

  const getIsWaitlisted = () => opsTasks.deployment.isWaitlisted;

  const getIsSpecialStatus = () => {
    return (
      getIsPrelaunch() ||
      getIsWaitlisted() ||
      getIsDQed() ||
      getIsSoftWaitlisted()
    );
  };

  const getIsSoftWaitlisted = () => opsTasks.deployment.isSoftWaitlisted;

  const updateEnrollmentStatus = async (
    options?: UpdateEnrollmentStatusOptions
  ) => {
    const abortController = options?.abortController || new AbortController();

    let currentCoverages: EnrollmentContextType["coverages"] = coverages;
    let currentScheduledHpCalls: EnrollmentContextType["scheduledHpCalls"] =
      scheduledHpCalls;
    let currentScheduledEcCalls: EnrollmentContextType["scheduledEcCalls"] =
      scheduledEcCalls;
    let currentScheduledQcCalls: EnrollmentContextType["scheduledQcCalls"] =
      scheduledQcCalls;

    try {
      setIsRetrievingStatus(true);

      const response = await epGet(`/all-enrollment-statuses`, {
        ...(abortController && { signal: abortController.signal }),
      });

      if (abortController?.signal.aborted) {
        return;
      }

      const {
        labsRequirement,
        opsTasks: retrievedOpsTasks,
        status,
        tasks,
        user: retrievedUser,
        steps: retrievedEnrollmentSteps,
        segments,
        hasRequiredActions: retrievedHasRequiredActions,
      }: {
        labsRequirement: LabRequirementType;
        opsTasks: OpsTask;
        status: any;
        tasks: any;
        user: User;
        steps: EnrollmentSteps;
        segments: Segments;
        hasRequiredActions: boolean;
      } = response;
      const {
        earlyQualification: taskEarlyQualification,
        healthHistory: taskHealthHistory,
      } = tasks;

      if (retrievedUser) {
        window.virta = window.virta || {};
        window.virta.user = window.virta.user || {};
        window.virta.user.virta_id = retrievedUser.virtaId;
      }

      setEarlyQualification(taskEarlyQualification);
      setEnrollmentStatus(status);
      setOpsTasks(retrievedOpsTasks);
      setHealthHistory(taskHealthHistory);
      setLabsRequirement(labsRequirement);
      setEnrollmentSteps(retrievedEnrollmentSteps);
      setHasRequiredActions(retrievedHasRequiredActions);
      setSegments(segments);

      const preferredLanguage = get(
        find(retrievedUser?.communication, (c) => c.preferred),
        "languageCode"
      );
      setUser(assign(retrievedUser, { preferredLanguage }));

      if (preferredLanguage === "es") {
        setLocale("es");
      }

      if (retrievedUser.phoneVerified) {
        setIsPhoneVerified(true);
      }

      if (
        segments.eligibilityRequirement === "required" &&
        coverages.length === 0
      ) {
        const retrievedCoverages = await getCoverages({
          epGet,
          abortController,
          onError: reportError,
        });

        if (retrievedCoverages) {
          currentCoverages = retrievedCoverages;
        }
      }

      if (
        segments.clinicalIntakeCall === "required" &&
        currentScheduledHpCalls.length === 0
      ) {
        const retrievedScheduledHpCalls = await getScheduledCalls({
          epGet,
          abortController,
          callType: "cic",
          onError: reportError,
        });

        if (retrievedScheduledHpCalls) {
          currentScheduledHpCalls = retrievedScheduledHpCalls;
        }
      }

      if (
        segments.enrollmentCall === "required" &&
        currentScheduledEcCalls.length === 0
      ) {
        const retrievedScheduledEcCalls = await getScheduledCalls({
          epGet,
          abortController,
          callType: "ec",
          onError: reportError,
        });

        if (retrievedScheduledEcCalls) {
          currentScheduledEcCalls = retrievedScheduledEcCalls;
        }
      }

      if (
        segments.qualificationCall === "required" &&
        currentScheduledQcCalls.length === 0
      ) {
        const retrievedScheduledQcCalls = await getScheduledCalls({
          epGet,
          abortController,
          callType: "qc",
          onError: reportError,
        });

        if (retrievedScheduledQcCalls) {
          currentScheduledQcCalls = retrievedScheduledQcCalls;
        }
      }

      setIsRetrievingStatus(false);
      setCoverages(currentCoverages);
      setScheduledHpCalls(currentScheduledHpCalls);
      setScheduledEcCalls(currentScheduledEcCalls);
      setScheduledQcCalls(currentScheduledQcCalls);
      setIsContextLoaded(true);

      return response;
    } catch (e: unknown) {
      if (e instanceof ResponseError) {
        if (e.status === 404) {
          throw messages.apiErrorMaintenance;
        } else if (e.status === 401) {
          // TODO: preserving the status quo and doing nothing for now - we shouldn't be calling
          // GET /all-enrollment-statuses if the user isn't logged in but need to investigate
        } else {
          throw messages.apiError;
        }
      }
    }
  };

  return {
    coverages,
    earlyQualification,
    enrollmentStatus,
    featureFlags,
    getIsDQed,
    getIsPrelaunch,
    getIsSoftWaitlisted,
    getIsSpecialStatus,
    getIsWaitlisted,
    hasJustSubmittedBI,
    hasRequiredActions,
    healthHistory,
    isContextLoaded,
    isPhoneVerified,
    isRetrievingStatus,
    labsRequirement,
    opsTasks,
    scheduledHpCalls,
    scheduledEcCalls,
    scheduledQcCalls,
    setFeatureFlags,
    setHasJustSubmittedBI,
    updateEnrollmentStatus,
    enrollmentSteps,
    user,
    segments,
  };

  function reportError(error: unknown) {
    reportAnyError(error, {
      sendToSentry: true,
      sentryContext: {
        user: {
          id: user.virtaId,
        },
      },
    });
  }
};
/* eslint-enable max-lines */
