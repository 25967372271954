import React from "react";

import { HealthProfile as AtlasHealthProfile } from "@virtahealth/experiences";

import styles from "./styles.css";

const HealthProfile = () => {
  return (
    <div className={styles.healthHistoryContainer}>
      <AtlasHealthProfile />
    </div>
  );
};

export default HealthProfile;
