import { noop } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

import {
  RequestProvider as AtlasRequestProvider,
  RequestOptions,
} from "@virtahealth/experiences";

import { UnauthenticatedRequestError } from "../utils/api_errors";
import { epGet, epPatch, epPost } from "../utils/ep_api";

/**
 * This context wraps our API request layer so that we can do neat stuff like use history
 */

export interface RequestContextValue {
  epGet: (
    endpoint: string,
    options?: RequestOptions,
    expectError?: boolean
  ) => Promise<any>;
  epPatch: <T>(
    endpoint: string,
    payload: T,
    options?: RequestOptions
  ) => Promise<any>;
  epPost: <T>(
    endpoint: string,
    payload: T,
    options?: RequestOptions
  ) => Promise<any>;
}

const RequestContext = React.createContext<RequestContextValue>({
  epGet: () => Promise.resolve(), // empty promises
  epPost: () => Promise.resolve(),
  epPatch: () => Promise.resolve(),
});

export const RequestProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { push, location } = useHistory();
  const contextValue = {
    epGet: WrappedRequest(epGet, push, location),
    epPost: WrappedRequest(epPost, push, location),
    epPatch: WrappedRequest(epPatch, push, location),
  };
  return (
    <RequestContext.Provider value={contextValue}>
      <AtlasRequestProvider {...contextValue} on401Exception={noop}>
        {children}
      </AtlasRequestProvider>
    </RequestContext.Provider>
  );
};

export const useRequestContext = () => React.useContext(RequestContext);

const WrappedRequest =
  (
    fn: any,
    push: (route: string) => void,
    location: ReturnType<typeof useHistory>["location"]
  ) =>
  (...args: any[]) => {
    return fn(...args).catch((e: any) => {
      if (e instanceof UnauthenticatedRequestError) {
        if (!location.pathname.includes("/user/login")) {
          // TODO: maybe clear auth from storage here too
          push(
            location.search ? `/user/login${location.search}` : "/user/login"
          );
        }
      } else {
        throw e;
      }
    });
  };

export default RequestContext;
