import { ENROLLMENT_SIGN_UP_FLOW } from "@virtahealth/experiences/src/EnrollmentForms/utils/ld_flag_constants";

import { logGenericEvent } from "../../../utils/analytics_client";
import { BI_FIRST } from "../variations";

const EXPERIMENT_NAME = "engagingsignupflow-experiment1";
const LD_FEATURE_FLAG_NAME = ENROLLMENT_SIGN_UP_FLOW;
const LD_VARIANT = BI_FIRST;

/*
 * PRIORITY 1 EVENTS
 * Markers for the start and end of the flow
 */
export const logViewedLeadCapturePage = (sessionId: string) => {
  logGenericEvent(`Viewed Lead Capture page`, {
    session_id: sessionId,
    ld_experiment_name: EXPERIMENT_NAME,
    ld_feature_flag_name: LD_FEATURE_FLAG_NAME,
    ld_variant: LD_VARIANT,
  });
};

export const logViewedSignUpConfirmation = (
  sessionId: string,
  virtaId: string
) => {
  logGenericEvent(`Viewed Sign-Up confirmation`, {
    session_id: sessionId,
    virta_id: virtaId,
    ld_experiment_name: EXPERIMENT_NAME,
    ld_feature_flag_name: LD_FEATURE_FLAG_NAME,
    ld_variant: LD_VARIANT,
  });
};

/*
 * PRIORITY 2 EVENTS
 * Markers for views on the intermediate pages between start and end
 */
export const logViewedDisqualifiedPage = (sessionId: string) => {
  logGenericEvent(`Viewed Disqualified page`, {
    session_id: sessionId,
    ld_experiment_name: EXPERIMENT_NAME,
    ld_feature_flag_name: LD_FEATURE_FLAG_NAME,
    ld_variant: LD_VARIANT,
  });
};

export const logClickedEditResponsesOnDisqualifiedPage = (
  sessionId: string
) => {
  logGenericEvent(`Clicked Edit Responses on Disqualified page`, {
    session_id: sessionId,
    ld_experiment_name: EXPERIMENT_NAME,
    ld_feature_flag_name: LD_FEATURE_FLAG_NAME,
    ld_variant: LD_VARIANT,
  });
};

export const logViewedSMSVerificationProperties = (
  sessionId: string,
  virtaId: string
) => {
  return {
    session_id: sessionId,
    virta_id: virtaId,
    ld_experiment_name: EXPERIMENT_NAME,
    ld_feature_flag_name: LD_FEATURE_FLAG_NAME,
    ld_variant: LD_VARIANT,
  };
};

/*
 * PRIORITY 3 EVENTS
 * Markers for various button click events
 */
export const logClickedSignUpStarted = (sessionId: string) => {
  logGenericEvent(`Clicked Sign-up Started`, {
    session_id: sessionId,
    ld_experiment_name: EXPERIMENT_NAME,
    ld_feature_flag_name: LD_FEATURE_FLAG_NAME,
    ld_variant: LD_VARIANT,
  });
};

export const logClickedBackOnBasicInformationPage = (sessionId: string) => {
  logGenericEvent(`Clicked Back on Basic Information page`, {
    session_id: sessionId,
    ld_experiment_name: EXPERIMENT_NAME,
    ld_feature_flag_name: LD_FEATURE_FLAG_NAME,
    ld_variant: LD_VARIANT,
  });
};

export const logClickedCTAOnBasicInformationPage = (sessionId: string) => {
  logGenericEvent(`Clicked CTA on Basic Information page`, {
    session_id: sessionId,
    ld_experiment_name: EXPERIMENT_NAME,
    ld_feature_flag_name: LD_FEATURE_FLAG_NAME,
    ld_variant: LD_VARIANT,
  });
};

export const logClickedBackOnShortenedAccountCreationPage = (
  sessionId: string
) => {
  logGenericEvent(`Clicked Back on Shortened Account Creation page`, {
    session_id: sessionId,
    ld_experiment_name: EXPERIMENT_NAME,
    ld_feature_flag_name: LD_FEATURE_FLAG_NAME,
    ld_variant: LD_VARIANT,
  });
};

export const logClickedCTAOnShortenedAccountCreationPage = (
  sessionId: string
) => {
  logGenericEvent(`Clicked CTA on Shortened Account Creation page`, {
    session_id: sessionId,
    ld_experiment_name: EXPERIMENT_NAME,
    ld_feature_flag_name: LD_FEATURE_FLAG_NAME,
    ld_variant: LD_VARIANT,
  });
};

export const logClickedCTAOnSMSVerificationPage = (
  sessionId: string,
  virtaId: string
) => {
  logGenericEvent(`Clicked CTA on SMS Verification page`, {
    session_id: sessionId,
    virta_id: virtaId,
    ld_experiment_name: EXPERIMENT_NAME,
    ld_feature_flag_name: LD_FEATURE_FLAG_NAME,
    ld_variant: LD_VARIANT,
  });
};
