import { replace } from "lodash";
import React from "react";

import { ReferralCompleteScreen } from "@virtahealth/experiences";

import { useEnrollmentContext } from "../contexts/enrollment";

import styles from "./styles.css";

const ReferralComplete = () => {
  const enrollment = useEnrollmentContext();
  const { earlyQualification } = enrollment;
  const { referralChannelOptOther, employerId } = earlyQualification;

  const simplifiedReferrer = replace(referralChannelOptOther ?? "", "ext_", "");

  return (
    <div className={styles.referralCompleteContainer}>
      <ReferralCompleteScreen
        onGoToAdditionalReferrals={() => {
          window.location.replace(
            `/apply/create-referral?employer_id=${employerId}&referrer=${simplifiedReferrer}`
          );
        }}
      />
    </div>
  );
};

export default ReferralComplete;
