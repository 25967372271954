import React from "react";

import {
  LeadAccountCreationScreenFormValues,
  LeadCaptureScreenFormValues,
} from "@virtahealth/experiences";

interface LinkedAccountId {
  [key: string]: string;
}

interface User
  extends Partial<LeadCaptureScreenFormValues>,
    Partial<LeadAccountCreationScreenFormValues> {
  linkedAccountIds: LinkedAccountId | undefined;
}

interface Disqualification {
  customerReason?: string;
  disqualificationId?: string;
}
interface ReferralResponse {
  status: {
    disqualifications?: Array<Disqualification>;
    enrollmentIndicator?: string;
  };
  applicationDisplayName?: string;
}

interface EarlyQualificationTask {
  heightFeet?: any;
  heightInches?: any;
  employerId?: string;
  referrerUrl?: string;
  referralChannelOptOther?: string;
}

interface ApplyContextValue {
  user: User;
  setLanguage?: (language: React.SetStateAction<string>) => void;
  setUser?: (value: React.SetStateAction<User>) => void;
  setEarlyQualificationTask?: (
    value: React.SetStateAction<EarlyQualificationTask>
  ) => void;
  setReferralResponse?: (value: React.SetStateAction<ReferralResponse>) => void;
  language: string;
  earlyQualificationTask: EarlyQualificationTask;
  referralResponse: ReferralResponse;
}

export const defaultApplyContext = {
  user: {
    dateOfBirth: undefined,
    mobile: undefined,
    password: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    linkedAccountIds: undefined,
  },
  earlyQualificationTask: {
    employerId: undefined,
    referrerUrl: undefined,
    heightInches: undefined,
    heightFeet: undefined,
  },
  language: "en",
  referralResponse: {
    status: {
      disqualifications: [],
      enrollmentIndicator: undefined,
    },
    applicationDisplayName: undefined,
  },
};

export const ApplyContext =
  React.createContext<ApplyContextValue>(defaultApplyContext);
