/* eslint-disable max-lines */
import { FormikValues } from "formik";
import { assign, isNil, startsWith } from "lodash";
import React from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { VirtaContextComponents } from "@virtahealth/components";
import { Spinner } from "@virtahealth/design-components";
import { DynamicForm } from "@virtahealth/experiences";
import { DynamicFormSchema } from "@virtahealth/experiences/src/DynamicForm/types";
import { messageTree } from "@virtahealth/utils";

import { DTP_DEPLOYMENT_KEY } from "../constants/Enrollment";
import { useEnrollmentContext } from "../contexts/enrollment";
import { useRequestContext } from "../contexts/request";
import { sendAmplitudeScreenViewEvent } from "../hooks/amplitudeEffects";

import {
  createOrUpdateCoverageReq,
  updateUserReq,
  uploadImageReq,
} from "./apis";
import styles from "./styles.css";

interface EligibilityResponse {
  outcome: string;
  discrepancies: string[];
}

const stepNames = {
  assignmentOfBenefitConsent: 0,
  verifyingCoverage: 1,
  eligible: 2,
  insuranceInfoCorrection: 3,
  troubleshooting: 4,
  troubleshootingInput: 5,
  thanksForSubmittingYourInformation: 6,
  imageUpload: 7,
  investigation: 8,
};

/**
 * Component to implement dynamic form version of eligibility, older version based on Atlas component is
 * www/static/js/legacy_eligibility which we will remove after thoroughly testing this component
 */
const Eligibility = () => {
  const {
    coverages,
    earlyQualification,
    healthHistory,
    isRetrievingStatus,
    opsTasks,
    updateEnrollmentStatus,
    user,
  } = useEnrollmentContext();
  const { analyticsClient } = React.useContext(VirtaContextComponents);
  const intl = useIntl();
  const { push } = useHistory();
  const location = useLocation();

  const [schema, setSchema] = React.useState<DynamicFormSchema>();
  const [page, setPage] = React.useState(0);
  const [prevPage, setPrevPage] = React.useState(0);
  const contextCoverage = coverages.pop();
  const [coverage, setCoverage] = React.useState(contextCoverage);
  const [isLoadingEligibilityResponse, setIsLoadingEligibilityResponse] =
    React.useState(true);
  const [isLoadingSchema, setIsLoadingSchema] = React.useState(true);
  const [eligibilityResponse, setEligibilityResponse] =
    React.useState<EligibilityResponse>();
  const [pollingTimer, setPollingTimer] = React.useState(0);
  const [isPollingEligibility, setIsPollingEligibility] = React.useState(false);
  // const [troubleshootingAttempt, setTroubleshootingAttempt] = React.useState(0);
  const { epGet, epPatch, epPost } = useRequestContext();

  const abortController = new AbortController();

  const isReferral = startsWith(
    earlyQualification.referralChannelOptOther || "",
    "ext_"
  );

  const onContinueLocation = () => {
    if (location.pathname.includes("referral")) {
      return "/enrollment/referral-complete";
    } else {
      return "/enrollment";
    }
  };

  // gets the schema to display the form
  React.useEffect(() => {
    const getSchema = async () => {
      const response = await epGet(`/forms/eligibility`);
      if (abortController.signal.aborted) {
        return;
      }
      setSchema(response);
      setIsLoadingSchema(false);
    };
    if (isLoadingSchema) {
      getSchema();
    }
  }, [isLoadingSchema]);

  // set a polling timer if we are in the process of polling for a normalized eligibility response
  // should only be triggered by setting the polling eligibility state
  React.useEffect(() => {
    // if we turned it off
    if (!isPollingEligibility) {
      setPollingTimer(0);
    } else {
      // no existing timer
      if (pollingTimer === 0) {
        setPollingTimer(15);
      }
    }
  }, [isPollingEligibility]);

  // redirect logic for the polling timer, also decrements timer
  React.useEffect(() => {
    const handlePollingTimer = async () => {
      if (pollingTimer > 0 && isPollingEligibility) {
        // if we aren't currently polling
        if (pollingTimer === 0) {
          setPollingTimer(15);
        }
        // send to polling page
        setPage(stepNames.verifyingCoverage);
        if (pollingTimer === 1) {
          // push to timeout page
          setPage(stepNames.investigation);
          setIsPollingEligibility(false);
        }
        if (pollingTimer % 5 === 0) {
          setIsLoadingEligibilityResponse(true);
        }
        // wait for a second
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setPollingTimer(pollingTimer - 1);
      }
    };
    handlePollingTimer();
  }, [isPollingEligibility, pollingTimer]);

  React.useEffect(() => {
    const fetchEligibilityResponses = async () => {
      const retrievedEligibilityResponse = await epGet(
        `/normalized_eligibility_responses`,
        {},
        true
      ).catch((err) => {
        if (err.status === 404) {
          // ignore
        }
      });
      if (retrievedEligibilityResponse) {
        setIsPollingEligibility(false);
        setEligibilityResponse(retrievedEligibilityResponse);
      }

      if (abortController.signal.aborted) {
        return;
      }
      setIsLoadingEligibilityResponse(false);
    };

    if (isLoadingEligibilityResponse) {
      fetchEligibilityResponses();
    }

    // removed abort controller because of weird behavior
    return () => {};
  }, [isLoadingEligibilityResponse]);

  React.useEffect(() => {
    // discrepancies sends us to insuranceInfoCorrection page
    if (eligibilityResponse) {
      setIsPollingEligibility(false);

      if (eligibilityResponse.outcome === "Eligible") {
        setPage(stepNames.eligible);
        return;
      } else if (
        eligibilityResponse.discrepancies &&
        eligibilityResponse.discrepancies.length > 0
      ) {
        setPage(stepNames.insuranceInfoCorrection);
        return;
      }
      // // ineligible w/o discrepancies sends us to troubleshooting page (troubleshooting)
      // if (
      //   eligibilityResponse.outcome === "Ineligible" &&
      //   troubleshootingAttempt < 1
      // ) {
      //   setTroubleshootingAttempt(troubleshootingAttempt + 1);
      //   setPage(stepNames.troubleshooting);
      //   return;
      // }
      // if (
      //   eligibilityResponse.outcome === "Ineligible" &&
      //   troubleshootingAttempt === 1
      // ) {
      else {
        // setTroubleshootingAttempt(troubleshootingAttempt + 1);
        setPage(stepNames.thanksForSubmittingYourInformation);
        return;
      }
    }
  }, [eligibilityResponse]);

  const handleSubmit = async (values: FormikValues) => {
    switch (page) {
      case stepNames.assignmentOfBenefitConsent:
        {
          if (abortController.signal.aborted) {
            return;
          }
          setPage(stepNames.verifyingCoverage);
          try {
            const coverageReqData = createOrUpdateCoverageReq(
              values,
              coverage?.coverageId
            );
            const newCoverage = !isNil(coverage?.coverageId)
              ? await epPatch("/normalized-coverages", coverageReqData)
              : await epPost("/normalized-coverages", coverageReqData);

            setCoverage(newCoverage);

            // submit aob acceptance as well
            if ("acceptAssignmentOfBenefitConsent" in values) {
              await epPatch("/applicant_tasks/health_history", {
                aob_accepted: values.acceptAssignmentOfBenefitConsent,
                is_complete: false,
              });
            }

            // if DTP the user goes straight to covered, otherwise we need to wait for eligibility response
            opsTasks.deployment.deploymentKey === DTP_DEPLOYMENT_KEY ||
            isReferral
              ? setPage(stepNames.thanksForSubmittingYourInformation)
              : setIsPollingEligibility(true);
          } catch (e) {
            console.error(e);
            setPage(stepNames.thanksForSubmittingYourInformation);
          }
        }
        break;

      case stepNames.eligible:
      case stepNames.thanksForSubmittingYourInformation:
      case stepNames.investigation:
        await updateEnrollmentStatus();

        push(onContinueLocation());
        break;

      case stepNames.insuranceInfoCorrection:
        if (abortController.signal.aborted) {
          return;
        }
        try {
          const coverageReqData = createOrUpdateCoverageReq(
            values,
            coverage?.coverageId
          );
          !isNil(coverage?.coverageId)
            ? await epPatch("/normalized-coverages", coverageReqData)
            : await epPost("/normalized-coverages", coverageReqData);
        } catch (e) {
          console.error(e);
          setPage(stepNames.thanksForSubmittingYourInformation);
        }
        try {
          await epPatch("/users/self", updateUserReq(values));
        } catch (e) {
          console.error(e);
          setPage(stepNames.thanksForSubmittingYourInformation);
        }
        if (eligibilityResponse?.outcome === "Eligible") {
          setPage(stepNames.eligible);
        } else {
          setIsPollingEligibility(true);
        }
        break;
      case stepNames.troubleshootingInput:
        {
          if (abortController.signal.aborted) {
            return;
          }
          try {
            const coverageReqData = createOrUpdateCoverageReq(
              values,
              coverage?.coverageId
            );
            !isNil(coverage?.coverageId)
              ? await epPatch("/normalized-coverages", coverageReqData)
              : await epPost("/normalized-coverages", coverageReqData);
          } catch (e) {
            console.error(e);
            setPage(stepNames.troubleshooting);
          }
          try {
            await epPatch("/users/self", updateUserReq(values));
          } catch (e) {
            console.error(e);
            setPage(stepNames.troubleshooting);
          }

          setIsPollingEligibility(true);
        }
        break;

      case stepNames.imageUpload:
        {
          if (abortController.signal.aborted) {
            return;
          }
          try {
            await epPost("/normalized-coverages", uploadImageReq(values));
            setPage(stepNames.investigation);
          } catch (e) {
            setPage(stepNames.troubleshooting);
          }
        }
        break;

      default:
        setPage(page + 1);
    }

    if (page !== stepNames.verifyingCoverage) {
      setPrevPage(page);
    }
    return Promise.resolve();
  };

  const handleGoBack = () => {
    if (page === stepNames.troubleshooting) {
      // User clicked skip
      setPage(stepNames.verifyingCoverage);
      setTimeout(
        () => setPage(stepNames.thanksForSubmittingYourInformation),
        1000
      );
    } else {
      setPage(prevPage);
    }
  };

  const handleSkipPage = async () => {
    await updateEnrollmentStatus();
    push(onContinueLocation());
  };

  interface GenderMap {
    m: "male";
    o: "other";
    f: "female";
  }

  type Gender = "m" | "o" | "f";

  const genderMap: GenderMap = {
    m: "male",
    o: "other",
    f: "female",
  };

  let initialValues = {
    ...healthHistory,
    dateOfBirth: user.dateOfBirth,
    gender: user.gender,
    genderString: intl.formatMessage(
      messageTree.common.profile[
        genderMap[user.gender as Gender]
      ] as MessageDescriptor
    ),
    ...coverage,
    eligibilityResponse,
    deploymentName: opsTasks.deployment.applicationDisplayName,
  };

  if (!isNil(healthHistory.aobAccepted)) {
    initialValues = assign(initialValues, {
      acceptAssignmentOfBenefitConsent: healthHistory.aobAccepted,
    });
  }

  return isLoadingSchema ||
    (isLoadingEligibilityResponse && !isPollingEligibility) ? (
    <div id="eligibility-loading-container" className={styles.spinnerContainer}>
      <Spinner size="large" />
    </div>
  ) : (
    <div className={styles.eligibilityContainer}>
      <DynamicForm
        onGoBack={handleGoBack}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        schema={schema as DynamicFormSchema}
        page={page}
        isApiLoading={isRetrievingStatus}
        onSkip={handleSkipPage}
        sendAmplitudeLogEvent={sendAmplitudeScreenViewEvent(analyticsClient)}
      />
    </div>
  );
};

export default Eligibility;
/* eslint-enable max-lines */
