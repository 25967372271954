import React from "react";

import { VirtaContextComponents } from "@virtahealth/components";
import {
  BodyText,
  Button,
  HeadingText,
  Pressable,
} from "@virtahealth/design-components";
import { Icon, IconNames } from "@virtahealth/design-icons";
import { Flex, Row } from "@virtahealth/design-layout";
import { useBool } from "@virtahealth/hooks";
import { useVirtaIntl } from "@virtahealth/translations";
import { isMobileBrowser, messages } from "@virtahealth/utils";

import { textOrOpenAppDownloadLink } from "../../../utils/app_download";
import PAModal, { PAModalBody, PAModalHeader } from "../../PAModal";
import { MODAL_MAX_WIDTH, useNavBarBreakpoints } from "../utils";

type ModalName = "history" | "messaging" | "community";

interface NavBarItemProps {
  iconName: IconNames;
  text: string;
  selected?: boolean;
  onPress?: () => void;
  orientation?: "horizontal" | "vertical";
}

const TeaserNavBarItem = ({
  iconName,
  onPress,
  orientation = "horizontal",
  selected,
  text,
}: NavBarItemProps) => {
  return (
    <Pressable onPress={onPress} testID={`nav-bar-item-${text}`}>
      <Flex
        gap={1}
        direction={orientation === "horizontal" ? "row" : "column"}
        align={orientation === "horizontal" ? "stretch" : "center"}
      >
        <Icon name={iconName} stroke={selected ? "blue-700" : "neutral-700"} />
        <BodyText
          size="small"
          weight="bold"
          text={text}
          color={selected ? "blue-700" : "neutral-700"}
        />
      </Flex>
    </Pressable>
  );
};

interface HiddenModalProps {
  name: ModalName;
  onClose: () => void;
  open: boolean;
}

export const HiddenFeatureModal = ({
  onClose,
  open,
  name,
}: HiddenModalProps) => {
  const { formatMessageAccessibility } = useVirtaIntl();

  const { analyticsClient } = React.useContext(VirtaContextComponents);

  const modalBodyText = (modalName: ModalName) => {
    switch (modalName) {
      case "history":
        return messages.historyFeatureDescription;
      case "messaging":
        return messages.messagingFeatureDescription;
      case "community":
        return messages.communityFeatureDescription;
    }
  };

  const modalHeaderText = (modalName: ModalName) => {
    switch (modalName) {
      case "messaging":
        return messages.availableNowInApp;
      case "community":
      case "history":
        return messages.previewInApp;
    }
  };

  return (
    <PAModal
      isOpen={open}
      style={{ content: { maxWidth: MODAL_MAX_WIDTH } }}
      requestCloseFn={onClose}
      contentLabel={formatMessageAccessibility(messages.confirmLogout)}
    >
      <PAModalHeader style={{ justifyContent: "center" }}>
        <HeadingText weight="bold" text={modalHeaderText(name)} />
      </PAModalHeader>
      <PAModalBody>
        <Flex direction="column" gap={2}>
          <BodyText size="large" text={modalBodyText(name)} />
          <Button
            text={
              isMobileBrowser()
                ? messages.downloadAppNow
                : messages.sendDownloadText
            }
            onPress={() =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              textOrOpenAppDownloadLink(analyticsClient?.logCustomEvent!)
            }
            testID="modal-download-button"
          />
        </Flex>
      </PAModalBody>
    </PAModal>
  );
};

export const TeaserNavBar = () => {
  const { isLargeScreen } = useNavBarBreakpoints();
  const [modalOpen, { on: openModal, off: closeModal }] = useBool(false);
  const [selectedModal, setSelectedModal] =
    React.useState<ModalName>("history");

  const showModal = (modalName: ModalName) => () => {
    setSelectedModal(modalName);
    openModal();
  };

  const orientation = isLargeScreen ? "horizontal" : "vertical";

  return (
    <>
      <Row
        justify="space-around"
        backgroundColor="white"
        paddingVertical={1}
        paddingHorizontal={2}
        gap={3}
      >
        <TeaserNavBarItem
          text="Today"
          iconName="menu-home"
          onPress={closeModal}
          selected
          orientation={orientation}
        />
        <TeaserNavBarItem
          text="History"
          iconName="history-alt"
          onPress={showModal("history")}
          orientation={orientation}
        />
        <TeaserNavBarItem
          text="Messaging"
          iconName="conversation-text"
          onPress={showModal("messaging")}
          orientation={orientation}
        />
        <TeaserNavBarItem
          text="Community"
          iconName="discover-alt"
          onPress={showModal("community")}
          orientation={orientation}
        />
      </Row>

      <HiddenFeatureModal
        onClose={closeModal}
        open={modalOpen}
        name={selectedModal}
      />
    </>
  );
};
