import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { VirtaContextComponents } from "@virtahealth/components";
import {
  BodyText,
  HeadingText,
  Hr,
  Link,
} from "@virtahealth/design-components";
import { Stack } from "@virtahealth/design-layout";
import { LoginForm, LoginFormValues } from "@virtahealth/participant-app";
import { tID } from "@virtahealth/testing";
import { messageTree, messages } from "@virtahealth/utils";

import { MAX_FORM_WIDTH } from "../constants/formSizes";
import { useAuth } from "../contexts/auth";
import { useScreenViewEffect } from "../hooks/amplitudeEffects";
import { post } from "../utils/api";

const LOGIN_PAGE_NAME = "Login Page";
const LOGIN_PATH = "/user/login";
const FORGOT_PASSWORD_PATH = "/user/forgot";
const ENROLL_NOW_PATH = "/apply";
const LOG_IN_BUTTON = "Log In Button";
const FORGOT_PASSWORD_LINK = "Forgot Password Link";
const ENROLL_NOW_LINK = "Enroll Now Link";

const APPLICANT_ACCOUNT_TYPE = "applicant";
const PATIENT_ACCOUNT_TYPE = "patient";

const defaultEventProperties = {
  screen_name: LOGIN_PAGE_NAME,
  screen_path: LOGIN_PATH,
};

export const Login: React.FC<{}> = () => {
  const auth = useAuth();
  const context = React.useContext(VirtaContextComponents);
  const { analyticsClient } = context;
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const next = params.get("next");
  const paramEmail = params.get("email");

  const onLogIn = async (values: LoginFormValues) => {
    const { email, password } = values;

    analyticsClient?.logClickEvent(LOG_IN_BUTTON, defaultEventProperties);

    try {
      const response: any = await post(
        `${LOGIN_PATH}${next ? `?next=${next}` : ""}`,
        { email, pwd: password }
      );
      const { accountType, virtaId } = response;

      if (virtaId) {
        window.virta = window.virta || {};
        window.virta.user = window.virta.user || {};
        window.virta.user.virta_id = virtaId;
      }

      auth.logIn(accountType);

      if (accountType === APPLICANT_ACCOUNT_TYPE) {
        history.push("/enrollment");
      }

      if (accountType === PATIENT_ACCOUNT_TYPE) {
        window.location.replace(response.next ?? "/");
        return;
      }
      // TODO: clean these up
    } catch (e) {
      if (e.status === 401) {
        throw messages.invalidLogin;
      }
      if (e.status === 404) {
        throw messages.apiErrorMaintenance;
      }
      throw messages.apiError;
    }
  };

  const onForgotPassword = () => {
    analyticsClient?.logClickEvent(FORGOT_PASSWORD_LINK, {
      ...defaultEventProperties,
      destination_path: FORGOT_PASSWORD_PATH,
    });
    window.location.replace(FORGOT_PASSWORD_PATH);
  };

  const onEnrollNow = () => {
    analyticsClient?.logClickEvent(ENROLL_NOW_LINK, {
      ...defaultEventProperties,
      destination_path: ENROLL_NOW_PATH,
    });
    history.push(ENROLL_NOW_PATH);
  };

  useScreenViewEffect(LOGIN_PAGE_NAME);

  return (
    <Stack width={"100%"} maxWidth={MAX_FORM_WIDTH} gap={4} padding={2}>
      <HeadingText
        text={messageTree.common.login.signIntoAccount}
        textAlign="center"
      />
      <LoginForm
        onForgotPassword={onForgotPassword}
        onLogIn={onLogIn}
        initialValues={{ email: paramEmail || "" }}
      />

      <Hr color="neutral-300" />

      <BodyText
        textAlign="center"
        text={[
          messageTree.enrollment.leadCapture.dontHaveAccount,
          {
            enrollButton: (
              <Link
                testID={tID("login-enrollNow-button")}
                text={messageTree.enrollment.leadCapture.enrollNow}
                onPress={onEnrollNow}
              />
            ) as any,
          },
        ]}
        onPress={onEnrollNow}
      />
    </Stack>
  );
};

export default Login;
