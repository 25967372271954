import { camelCase } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

import { Spinner } from "@virtahealth/design-components";
import {
  Disqualification,
  EnrollmentStatusScreen,
} from "@virtahealth/experiences";

import { ApplyContext } from "../ApplyContext";

import styles from "./styles.css";

const ReferralDQScreen: React.FC = () => {
  const { referralResponse, earlyQualificationTask } =
    React.useContext(ApplyContext);
  const { push } = useHistory();
  const { status, applicationDisplayName } = referralResponse;
  const { disqualifications } = status;
  if (!disqualifications) {
    return <Spinner />;
  }
  return (
    <div className={styles.referralDqContainer}>
      <EnrollmentStatusScreen
        status="disqualified"
        deployment={applicationDisplayName}
        isReferral
        disqualifications={disqualifications?.map((dq) => {
          const { disqualificationId } = dq;
          return camelCase(`dq_${disqualificationId}`) as Disqualification;
        })}
        nonQualifications={[]}
        goLiveDate={undefined}
        onInfoCalloutButtonPress={() =>
          push(
            `/apply/create-referral?employer_id=${earlyQualificationTask?.employerId}&referrer=${earlyQualificationTask?.referralChannelOptOther}`
          )
        }
      />
    </div>
  );
};

export default ReferralDQScreen;
