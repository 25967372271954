import React from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../contexts/auth";

import { AuthenticatedNavBar } from "./AuthenticatedNavBar";
import { UnauthenticatedNavBar } from "./UnauthenticatedNavBar";
import { TeaserNavBar } from "./components/TeaserNavBar";

const NEXT_STEPS_PATH = "/enrollment/next-steps";

// Specifically used for in-app enrollment.
// The goal is to eventually use this for everything.
export const NavBar: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();

  const { pathname } = history.location;

  // How to decide on middle bar? New context for enrollment? Add on to VirtaContext somehow?
  if (auth.isAuthenticated) {
    switch (pathname) {
      case NEXT_STEPS_PATH:
        return (
          <AuthenticatedNavBar>
            <TeaserNavBar />
          </AuthenticatedNavBar>
        );
      default:
        return <AuthenticatedNavBar />;
    }
  }
  return <UnauthenticatedNavBar />;
};
