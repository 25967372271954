import * as Sentry from "@sentry/react";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";

import { VirtaContext, VirtaContextComponents } from "@virtahealth/components";
import { Spinner } from "@virtahealth/design-components";
import { FeatureFlagProvider } from "@virtahealth/experiences";
import { base } from "@virtahealth/styles";

import AccountActivation from "../account_activation";
import Apply from "../apply";
import ErrorPage from "../common-components/ErrorPage";
import LanguageToggle from "../common-components/LanguageToggle";
import { NavBar } from "../common-components/NavBar";
import { AccountType, AuthProvider, useProvideAuth } from "../contexts/auth";
import { EnrollmentProvider } from "../contexts/enrollment";
import { LocaleContext, LocaleProvider } from "../contexts/locale";
import { RequestProvider } from "../contexts/request";
import EmailVerification from "../email_verification";
import Enrollment from "../enrollment";
import HealthInformationExchange from "../health_information_exchange";
import { useGraphQLClient } from "../hooks/useGraphQLClient";
import { initializeAnalyticsTracking } from "../lib/analytics";
import Login from "../login";
import { beforeSend } from "../sentry_config";
import { AnalyticsClient, logGenericEvent } from "../utils/analytics_client";
import { EPClient } from "../utils/ep_api";
import { setSentryContext } from "../utils/errors";
import { VirtaLaunchDarklyClient } from "../utils/ld_client";
import { PAClient } from "../utils/pa_client";

import styles from "./styles.css";
import { initializeFeatureFlagger } from "./utils";

const APPLY_ROOT = "/apply";
const ENROLLMENT_ROOT = "/enrollment";
const MEMBER_ROOT = "/";

const LOGIN_PATH = "/user/login";
const ACTIVATE_PATH = "/user/activate";

const client = new QueryClient();

const getInitialLocation = (options: {
  pendingLocation: string;
  isAuthenticated: boolean;
  accountType: AccountType;
}): string => {
  const { pendingLocation, isAuthenticated, accountType } = options;

  if (pendingLocation.startsWith(ACTIVATE_PATH)) {
    return pendingLocation;
  }

  if (!isAuthenticated) {
    return pendingLocation.startsWith(APPLY_ROOT)
      ? pendingLocation
      : LOGIN_PATH;
  }

  if (accountType == "patient") {
    return pendingLocation.startsWith(ENROLLMENT_ROOT) ||
      pendingLocation.startsWith(APPLY_ROOT)
      ? MEMBER_ROOT
      : pendingLocation;
  } else if (accountType == "applicant") {
    return pendingLocation.startsWith(APPLY_ROOT) ||
      pendingLocation.startsWith(LOGIN_PATH)
      ? ENROLLMENT_ROOT
      : pendingLocation;
  } else {
    // We attempt to guess accountType in the auth context if one doesn't exist in storage,
    // so shouldn't reach this branch
    throw new Error(`Unknown account type: ${accountType}`);
  }
};

/** This component enables history to be used in App. */
const AppWithRouting = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

const App = () => {
  if (window.env) {
    // TODO: browser tracing is currently disabled because our transaction quotas are not managed
    // and are exhausted at the beginning of the month
    // - See: https://virta.atlassian.net/browse/AG-3161
    Sentry.init({
      dsn: "https://54272c7f78c54ced85c68efa7737ffb6@sentry.io/1363841",
      environment: window.env.appEnvironment,
      release: window.env.releaseId,
      beforeSend,
    });

    if (!window.virta.amplitude) {
      initializeAnalyticsTracking(window.env.amplitudeApiKey);
    }
  }

  initializeFeatureFlagger(window.virta?.user?.virta_id);

  const [isLoading, setIsLoading] = React.useState(true);

  const graphQLClient = useGraphQLClient();
  const analyticsClient = new AnalyticsClient();
  const { push, location } = useHistory();
  const auth = useProvideAuth(analyticsClient);

  React.useEffect(() => {
    if (isLoading && !auth.isLoading) {
      push(
        getInitialLocation({
          pendingLocation: location.pathname + location.search,
          isAuthenticated: auth.isAuthenticated,
          accountType: auth.accountType,
        })
      );

      setIsLoading(false);
    }
  }, [
    push,
    isLoading,
    auth.isLoading,
    auth.isAuthenticated,
    auth.accountType,
    location.pathname,
    location.search,
  ]);

  React.useEffect(() => {
    if (auth.accountType) {
      Sentry.configureScope((scope: Sentry.Scope) =>
        scope.setTag("user_type", auth.accountType)
      );
    }
  }, [auth.accountType]);

  if (isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <AuthProvider auth={auth}>
      <LocaleProvider>
        <RequestProvider>
          <QueryClientProvider client={client}>
            <LocaleContext.Consumer>
              {({ locale }) => (
                <VirtaContext
                  theme={base}
                  client={new PAClient()}
                  analyticsClient={analyticsClient}
                  enrollmentClient={new EPClient()}
                  launchDarkly={VirtaLaunchDarklyClient}
                  locale={locale}
                  gqlClient={graphQLClient}
                >
                  <VirtaContextComponents.Consumer>
                    {() => (
                      <Sentry.ErrorBoundary
                        fallback={() => <ErrorPage />}
                        beforeCapture={(scope) => {
                          setSentryContext(scope, {
                            user: {
                              id: window.virta?.user?.virta_id,
                            },
                            tags: {
                              error_boundary: "true",
                            },
                          });
                        }}
                        onError={(error) => {
                          logGenericEvent("Experienced Page Crash", {
                            error,
                          });
                        }}
                      >
                        <div className={styles.centeredPage}>
                          <NavBar />
                          <Switch>
                            <Route
                              path="/apply"
                              render={() => (
                                <div className={styles.noHeaderContainer}>
                                  <Apply />
                                </div>
                              )}
                            />
                            <Route
                              path="/user/confirm-email-verification"
                              render={() => (
                                <div className={styles.noHeaderContainer}>
                                  <EmailVerification />
                                </div>
                              )}
                            />
                            <Route
                              path="/user/health-information-exchange"
                              render={() => (
                                <div className={styles.noHeaderContainer}>
                                  <HealthInformationExchange />
                                </div>
                              )}
                            />
                            <Route
                              path="/user/login"
                              render={() => (
                                <div className={styles.noHeaderContainer}>
                                  <Login />
                                </div>
                              )}
                            />
                            <Route
                              path="/user/activate"
                              render={() => (
                                <div className={styles.noHeaderContainer}>
                                  <AccountActivation />
                                </div>
                              )}
                            />
                            <Route
                              path="/enrollment"
                              render={() => (
                                <EnrollmentProvider>
                                  <FeatureFlagProvider>
                                    <Enrollment />
                                  </FeatureFlagProvider>
                                </EnrollmentProvider>
                              )}
                            />
                          </Switch>
                          <LanguageToggle />
                        </div>
                      </Sentry.ErrorBoundary>
                    )}
                  </VirtaContextComponents.Consumer>
                </VirtaContext>
              )}
            </LocaleContext.Consumer>
          </QueryClientProvider>
        </RequestProvider>
      </LocaleProvider>
    </AuthProvider>
  );
};

export default AppWithRouting;
