import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { ApplyContext } from "../ApplyContext";
import { AccountCreationOrchestrator } from "../account_creation_orchestrator";
import { EnrollmentProvider } from "../contexts/enrollment";
import ReferralDQ from "../referral_dq";
import ReferralInformation from "../referral_information";

export const Apply = () => {
  const [language, setLanguage] = React.useState("en");
  const [user, setUser] = React.useState({
    dateOfBirth: undefined,
    mobile: undefined,
    password: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    linkedAccountIds: undefined,
  });
  const [referralResponse, setReferralResponse] = React.useState({
    status: {},
    applicationDisplayName: undefined,
  });
  const [earlyQualificationTask, setEarlyQualificationTask] = React.useState({
    employerId: undefined,
    referrerUrl: undefined,
    heightFeet: undefined,
    heightInches: undefined,
  });
  const { search } = useLocation();

  return (
    <ApplyContext.Provider
      value={{
        language,
        setLanguage,
        user,
        referralResponse,
        setUser,
        earlyQualificationTask,
        setEarlyQualificationTask,
        setReferralResponse,
      }}
    >
      <Switch>
        <Route
          path="/apply/get-started"
          component={AccountCreationOrchestrator}
        />
        <Route exact path="/apply/create-referral">
          <EnrollmentProvider>
            <ReferralInformation />
          </EnrollmentProvider>
        </Route>
        <Route path="/apply/referral-disqualified" component={ReferralDQ} />
        <Route
          exact
          path="/apply"
          render={() => (
            <Redirect to={{ pathname: "/apply/get-started", search }} />
          )}
        />
      </Switch>
    </ApplyContext.Provider>
  );
};

export default Apply;
