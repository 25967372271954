import { isEmpty } from "lodash";
import React from "react";
import { Linking } from "react-native";
import { useHistory } from "react-router-dom";

import { BodyText, Link } from "@virtahealth/design-components";
import { DynamicFormSchema } from "@virtahealth/design-forms";
import { Flex, Margin } from "@virtahealth/design-layout";
import { getMessageFromTreeOrKey, messages } from "@virtahealth/utils";

import { useEnrollmentContext } from "../../contexts/enrollment";
import { useRequestContext } from "../../contexts/request";
import { useScreenViewEffect } from "../../hooks/amplitudeEffects";
import { useSchema } from "../../hooks/schema";
import { useZendeskWidget } from "../../hooks/zendeskEffects";
import styles from "../styles.css";

import LoadingSpinner from "./LoadingSpinner";
import SmsDynamicForm from "./SmsDynamicForm";

export interface FormValues {
  phoneNumber: string;
  mobilePhoneNumber?: string;
}

const useSmsUpdate = () => {
  const context = useEnrollmentContext();
  const history = useHistory();
  const { epPost, epPatch } = useRequestContext();
  const onUpdate = async (values: FormValues) => {
    const data = {
      primaryPhone: values.phoneNumber,
    };
    try {
      await epPatch("/users/self", data);
      await epPost("/auth/phone/verify/start", {});
      context.updateEnrollmentStatus();
      history.push("/enrollment/sms-verification");
    } catch (e) {
      if (e.status === 404) {
        throw messages.apijrrorMaintenance;
      }
      throw messages.apiError;
    }
  };
  return {
    onUpdate,
  };
};

export const SmsUpdate = () => {
  useZendeskWidget();
  const context = useEnrollmentContext();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { onUpdate } = useSmsUpdate();
  const [error, setError] = React.useState(null);
  const schema = useSchema("/forms/sms-update");

  useScreenViewEffect("SMS Verification - Phone Update");

  const onSubmit = async (values: FormValues) => {
    setError(null);
    setIsSubmitting(true);
    try {
      await onUpdate({ phoneNumber: values?.mobilePhoneNumber ?? "" });
    } catch (error) {
      setError(error);
    }
    setIsSubmitting(false);
  };

  if (isEmpty(schema)) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles.smsVerificationContainer}>
      <Flex direction="column" backgroundColor="white">
        <Margin top={-5}>
          <SmsDynamicForm
            error={
              error
                ? "enrollment.smsVerification.failedToSendSmsVerification"
                : undefined
            }
            schema={schema as unknown as DynamicFormSchema}
            page={0}
            onSubmit={onSubmit}
            isApiLoading={isSubmitting}
            onUnmount={() => null}
            initialValues={{ mobilePhoneNumber: context?.user.primaryPhone }}
          />
        </Margin>
        <Flex direction="row" align="center" justify="center">
          <BodyText
            text={[
              getMessageFromTreeOrKey(
                "enrollment.smsVerification.stillHavingTroubleSmsVerification"
              ),
              {
                e: (chunks) => (
                  <Link
                    text={chunks[0]}
                    onPress={() => {
                      Linking.openURL("mailto:support@virtahealth.com");
                    }}
                  />
                ),
              },
            ]}
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default SmsUpdate;
